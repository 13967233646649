import { useEffect, useState } from "react";
import Footer from "../footer/Footer";
import HeaderComponent from "../header/HeaderComponent";
import { orderStatus } from "../../services/checkout-service";
import "./OrderStatus.css";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { getCounsellorSessionDataById } from "../../services/counsellor-service";
import StyledButton from "../ui/StyledButton/StyledButton";
import Calendly from "./Calendly";
import WhatsappBtn from "../ui/WhatsappBtn";

export interface IOrderData {
  order_id: number;
  session_date: string;
  payment_status: string;
  session_time: string;
  counsellor_session_id: number;
  amount: number;
}

export interface ISessionData {
  duration: number;
  name: string;
  price: number;
  session_id: number;
  therapy_type: string;
  slashed_price: number;
}

const OrderStatus = () => {
  const [orderData, setOrderData] = useState<IOrderData>();
  const [sessionData, setSessionData] = useState<ISessionData | null>(null);
  const [err, setErr] = useState(false);
  const [doctorId, setDoctorId] = useState("");
  const navigate = useNavigate();
  const [sessionSelected, setIsSessionSelected] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [sessionTime, setSessionTime] = useState("");

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const id = queryParameters.get("id");
    localStorage.removeItem("Whatsapp");

    if (id) {
      orderStatus(id)
        .then((res) => {
          const data = res.data.data;

          setOrderData({
            order_id: data.booking_id,
            session_date: data.booking_data.booking_date,
            session_time: data.booking_data.booking_time,
            payment_status: data.payment_status,
            counsellor_session_id: data.booking_data.counsellor_session_id,
            amount: data.amount,
          });

          const id = localStorage.getItem("SessionId");
          const docId = localStorage.getItem("DoctorId");
          if (docId) setDoctorId(docId);
          getCounsellorSessionDataById(docId).then((res) => {
            const counsellorData = res.data.data;
            const data = counsellorData.filter(
              (data: any) => data.session_id == id
            );
            setSessionData(data[0]);
            setSessionTime(data[0].duration);
          });
        })
        .catch((error) => {
          console.log(error);
          setErr(true);
        });
    }
  }, []);

  const handleCalendly = () => {
    setIsSessionSelected(true);
    const time = localStorage.getItem("AppointmentDate");
    const timeStamp = time ? Date.parse(time) : Date.now();
    // const timeStamp = Date.parse(calendlyData.eventDate);
    const dateObject = new Date(timeStamp);
    const year = dateObject.getFullYear();
    const month =
      dateObject.getMonth() + 1 < 10
        ? "0" + (dateObject.getMonth() + 1)
        : dateObject.getMonth() + 1;
    const day =
      dateObject.getDate() < 10
        ? "0" + dateObject.getDate()
        : dateObject.getDate();
    const hour = dateObject.getHours();
    const minutes = dateObject.getMinutes() ? dateObject.getMinutes() : "00";
    const seconds = dateObject.getSeconds() ? dateObject.getSeconds() : "00";
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hour}:${minutes}:${seconds}`;
    setAppointmentDate(formattedDate || "");
    setAppointmentTime(formattedTime || "");
  };

  return (
    <>
      <HeaderComponent />
      <div className="page center p-20">
        {err && (
          <div>
            <div className="header-font thanks left color-healthship mb-20 pt-20">
              Due to some technical issue, we are unable to show status page.
              Please check your SMS for order confirmation messgae. If you are
              still facing issues, please call/whatsapp us on +91-8076200720
            </div>
          </div>
        )}
        {orderData?.payment_status == "FAILED" && (
          <div>
            <h2 className="small-header-font thanks color-healthship mb-20 pt-20">
              Payment Failure
            </h2>
            <div className="text-font mt-20 mb-20">
              Your transaction failed due to some technical issues. Please Try
              Again
            </div>
            <StyledButton
              className="button large"
              onClick={() => {
                navigate("/booking?id=" + doctorId);
              }}
            >
              Try Again
            </StyledButton>
          </div>
        )}
        {orderData?.payment_status == "SUCCESS" && (
          <div data-amount={orderData.amount}>
            <div className="none" id="purchase-amount">{orderData.amount}</div>
            {!sessionSelected && (
              <section
                style={{ fontSize: 12 }}
                className="bottom-widget p-10 mb-10"
              >
                <div className="white">Your Payment has been successful.</div>
                <div className="white">
                  Please select date and time of your session below. Note: You
                  will get order details once your session has been booked.
                </div>
              </section>
            )}

            <Calendly
              sessionTime={sessionTime}
              handleCalendly={handleCalendly}
            />
            {sessionSelected && (
              <div>
                <h2 className="header-font thanks left color-healthship mb-20 pt-20">
                  Thank You for trusting us. Your therapy session has been
                  booked.
                </h2>
                <p className="p-10 left text-font">
                  <span>Order Id:</span> <span>{orderData?.order_id}</span>
                </p>
                <section className="order-details rad-10 left text-font">
                  <p className="p-10 flex">
                    <span className="order-left">Session Date:</span>{" "}
                    <span className="ml-auto">
                      {appointmentDate + " "} {appointmentTime}
                    </span>
                  </p>
                  <p className="p-10 flex">
                    <span className="order-left">Total Sessions:</span>
                    <span className="ml-auto">{sessionData?.name}</span>
                  </p>
                  <h2 className="small-header-font" style={{ paddingLeft: 10 }}>
                    Items
                  </h2>
                  <p className="p-10 flex">
                    <span className="order-left">
                      {sessionData?.therapy_type}
                    </span>
                    <span className="ml-auto">Rs {orderData?.amount}</span>
                  </p>
                </section>
                <section className="judgement-free rad-10 left p-10 mt-20">
                  <h2
                    className="small-header-font"
                    style={{ paddingBottom: 10 }}
                  >
                    We provide judgement free care
                  </h2>
                  <p className="text-font">
                    We help you understand your problems and work on them in a
                    therapy session with our expert therapists.
                  </p>
                </section>
                <h2 className="color-healthship small-header-font thanks">
                  You will get an email confirmation soon.{" "}
                </h2>
              </div>
            )}
          </div>
        )}
      </div>
      <WhatsappBtn />
      <Footer />
    </>
  );
};

export default OrderStatus;
