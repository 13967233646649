import FAQElement from "./FaqElement";

interface FaqProps {
  faqData: any;
}

const FAQ = ({ faqData }: FaqProps) => {
  return (
    <section>
      <h2 className="small-header-font mb-10">FAQ</h2>
      {faqData.map((faq: any) => {
        return <FAQElement question={faq.question} answer={faq.answer} />;
      })}
    </section>
  );
};

export default FAQ;
