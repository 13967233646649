import "./App.css";
import Home from "./components/home/Home";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import Team from "./components/team/Team";
import Checkout from "./components/checkout/Checkout";
import Calendly from "./components/checkout/Calendly";
import DoctorDetails from "./components/team/DoctorDetails";
import Login from "./components/auth/login";
import FourZeroFour from "./components/404";
import PrivacyPolicy from "./components/static/privacyPolicy";
import { UnAuthRedirect } from "./utils/auth";
import AuthProvider from "./context/authContext";
import OrderStatus from "./components/checkout/OrderStatus";
import CheckoutProvider from "./context/checkoutContext";
import PaymentPage from "./components/checkout/PaymentPage";
import ADHD from "./components/assessment/ADHD";
import Depression from "./components/assessment/Depression";
import CSI from "./components/assessment/CSI";
import OWB from "./components/assessment/OWB";
import Anxiety from "./components/assessment/Anxiety";
import Terms from "./components/static/t&c";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "./utils/ErrorBoundary";

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    Component: Home,
  },
  {
    path: "/adhd",
    Component: ADHD,
  },
  {
    path: "/depression",
    Component: Depression,
  },
  {
    path: "/career-stress",
    Component: CSI,
  },
  {
    path: "/overall-well-being",
    Component: OWB,
  },
  {
    path: "/anxiety",
    Component: Anxiety,
  },
  {
    path: "/booking",
    Component: Checkout,
  },
  {
    path: "/login",
    Component: Login,
    loader: UnAuthRedirect,
  },
  {
    path: "/team",
    element: <Navigate replace to="/online-therapist-psychiatrist" />
  },
  {
    path: "/team/*",
    element: <Navigate replace to="/online-therapist-psychiatrist" />
  },
  {
    path: "/online-therapist-psychiatrist",
    Component: Team
  },
  {
    path: "/online-therapist-psychiatrist/:id",
    Component: DoctorDetails,
  },
  // {
  //   path: "/payment",
  //   Component: PaymentPage,
  // },
  {
    path: "/privacy-policy",
    Component: PrivacyPolicy,
  },
  {
    path: "/terms",
    Component: Terms,
  },
  {
    path: "*",
    Component: FourZeroFour,
  },
  // {
  //   path: "/order-history",
  //   Component: OrderHistory,
  //   loader: AuthRedirect,
  // },
  {
    path: "/order-status",
    Component: OrderStatus,
  },
]);

function App() {

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent}>
      <AuthProvider>
        <CheckoutProvider>
          <RouterProvider
            router={router}
            fallbackElement={<p>Initial Load...</p>}
          />
        </CheckoutProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
