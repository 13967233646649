
interface SpecialisationElementProps {
  imgUrl: string;
  specialisedText: string;
}

interface SpecialisationProps {
  specialisation: any;
}

const Specialisation = ({ specialisation }: SpecialisationProps) => {
  return (
    <section className="specialisation mt-20">
      <h2>I Specialize in</h2>
      <div className="spec-container">
        {specialisation.map((item: any) => {
         return <SpecialisationElement imgUrl={item?.imgUrl} specialisedText={item?.name} />
        })}
        {/* <div className="specialisation-element">
          <div>
            <img src={SP1} />
          </div>
          <p>Lorem Ipsum dolor sit amet</p>
        </div>
        <div className="specialisation-element">
          <div>
            <img src={SP2} />
          </div>
          <p>Lorem Ipsum dolor sit amet</p>
        </div>
        <div className="specialisation-element">
          <div>
            <img src={SP3} />
          </div>
          <p>Lorem Ipsum dolor sit amet</p>
        </div> */}
      </div>
    </section>
  );
};

const SpecialisationElement = ({
  imgUrl,
  specialisedText,
}: SpecialisationElementProps) => {
  return (
    <div className="specialisation-element">
      <div>
        <img src={imgUrl} />
      </div>
      <p className="text-font">{specialisedText}</p>
    </div>
  );
};

export default Specialisation;
