import React from 'react';
import TestimonialSectionCard from './TestimonialSectionCard';
import "./TestimonialSectionCard.css";

interface CardData {
  id: number; // Ensures unique identifier
  name: string;
  testimonial: string;
}

interface CardCarouselProps {
  cards: CardData[];
}

const TestimonialCardCarousel: React.FC<CardCarouselProps> = ({ cards }) => {
  return (
    <div className="card-carousel-test mb-100" style={{ marginTop: 0 }}>
      {cards.map((card) => (
        <TestimonialSectionCard key={card.id} {...card} />
      ))}
    </div>
  );
};

export default TestimonialCardCarousel;
