import { redirect } from "react-router-dom";

export const isAuthorized = () => {
    const token = localStorage.getItem("AuthToken");
    if(token) return true;
    return false;
}

export const AuthRedirect = () => {
    const token = localStorage.getItem("AuthToken");
    if (!token) return redirect("/login");
    return null;
}

export const UnAuthRedirect = () => {
    const token = localStorage.getItem("AuthToken");
    if (token) return redirect("/");
    return null;
}