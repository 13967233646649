import React from "react";
import "./Footer.css";
import { CopyRight } from "../icons";
import StyledButton from "../ui/StyledButton/StyledButton";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <section className="footer">
      <div>
        <h2 className="small-header-font m-10">
          If you're experiencing an emergency and need immediate help, call
          suicide prevention helplines or go to the nearest hospital.
        </h2>
        <div className="footer-sec">
          <div className="mr-50">
            <h2 className="header-font color-healthship">Assess</h2>
            <ul className="text-font footer-list">
              <li
                onClick={() => navigate("/overall-well-being")}
                className="pointer"
              >
                Overall Well-Being
              </li>
              <li onClick={() => navigate("/anxiety")} className="pointer">
                Anxiety
              </li>
              <li
                onClick={() => navigate("/career-stress")}
                className="pointer"
              >
                Career Stress
              </li>
              <li onClick={() => navigate("/depression")} className="pointer">
                Depression
              </li>
              <li onClick={() => navigate("/adhd")} className="pointer">
                ADHD
              </li>
            </ul>
          </div>
          <div>
            <h2 className="header-font color-healthship">Connect</h2>
            <ul className="text-font footer-list">
              <li>
                <a className=" pointer black" href="https://wa.me/918076200720">
                  Whatsapp
                </a>
              </li>
              <li>
                <a
                  className="pointer black"
                  href="https://www.instagram.com/healthship.in/"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  className="pointer black"
                  href="https://www.facebook.com/profile.php?id=61550577561310"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  className="pointer black"
                  href="https://www.linkedin.com/company/healthshipin/about/?viewAsMember=true"
                >
                  Linkedin
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="center">
          {/* <div>
            <p className="text-font mb-10 center">Subscribe to our emails</p>
            <div className="subscribe-input">
              <input
                className="subs-input text-font rad-10"
                placeholder="e.g., email@example.com"
              />
              <StyledButton type="button" className="ml-10 button small">
                Join
              </StyledButton>
            </div>
          </div> */}
          <div className="mt-20">
            <h2 className="header-font color-healthship">Have a Question?</h2>
            <p className="text-font mb-10">Email us at care@healthship.in</p>
          </div>
        </div>
        <div className="btm-footer small-header-font">
          <p className="footer-title">
            {" "}
            <CopyRight /> LastUnicorn Health Pvt. Ltd.
          </p>
          <p className="ml-auto mr-10" style={{ paddingLeft: 10 }}>
            All Rights Reserved
          </p>
          <div className="ml-10 mr-10 flex">
            <p onClick={() => navigate("/terms")} className="pointer mr-10">Terms and Conditions</p>
            <p onClick={() => navigate("/privacy-policy")} className="pointer" style={{ marginLeft: "auto" }}>Privacy Policy</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
