import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowRight,
  faXmark,
  faUser,
  faCopyright,
  faCopy,
  faStar,
  faStarHalf,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

export const ArrowRightIcon = () => {
  return (
    <>
      <FontAwesomeIcon icon={faArrowRight} />
    </>
  );
};

export const AngleUpIcon = () => {
  return (
    <>
      <FontAwesomeIcon className="icon-class" size="xl" icon={faAngleUp} />
    </>
  );
};

export const AngleDownIcon = () => {
  return (
    <>
      <FontAwesomeIcon className="icon-class" size="xl" icon={faAngleDown} />
    </>
  );
};

export const CloseModalIcon = () => {
  return (
    <>
      <FontAwesomeIcon icon={faXmark} size="2xl" />
    </>
  );
};

export const UserIcon = () => {
  return (
    <>
      <FontAwesomeIcon icon={faUser} />
    </>
  );
};

export const CopyRight = () => {
  return (
    <>
      <FontAwesomeIcon icon={faCopyright} />
    </>
  );
};

export const CopyClip = () => {
  return (
    <>
      <FontAwesomeIcon icon={faCopy} />
    </>
  );
};

export const Star = () => {
  return (
    <>
      <FontAwesomeIcon icon={faStar} style={{ color: "#ec7800" }} />
    </>
  );
};
<FontAwesomeIcon icon={faStarHalf} />;

export const HalfStar = () => {
  return (
    <>
      <FontAwesomeIcon icon={faStarHalf} style={{ color: "#ec7800" }} />
    </>
  );
};

export const CircleCheck = () => {
  return (
    <>
      <FontAwesomeIcon icon={faCircleCheck} style={{ color: "#08831a" }} />
    </>
  );
};
