import React from 'react';
import SectionCard from './SectionCard';

interface CardData {
  id: number; // Ensures unique identifier
  title: string;
  content: string;
  image?: string; // Optional image prop
}

interface CardCarouselProps {
  cards: CardData[];
}

const CardCarousel: React.FC<CardCarouselProps> = ({ cards }) => {
  return (
    <div className="card-carousel">
      {cards.map((card) => (
        <SectionCard key={card.id} {...card} />
      ))}
    </div>
  );
};

export default CardCarousel;
