import Assessment from "./Assessment";
import { useLocation } from 'react-router-dom';

const Anxiety = () => {
    const location = useLocation();
    const url = location.pathname.slice(1);

  return <Assessment page={url} />;
};

export default Anxiety;
