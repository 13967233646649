import { Circles } from "react-loader-spinner";


const LoaderModal = ( {isLoading}: {isLoading: boolean} ) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="loader-modal">
    <div className="loader">
      <Circles
        color="#000000"
        height={100}
        width={100}
        visible={isLoading}
      />
    </div>
    </div>

  );
};

export default LoaderModal;