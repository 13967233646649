import React from "react";
import "./TestimonialSectionCard.css";
import { CircleCheck, Star } from "../icons";
interface CardProps {
  name: string;
  testimonial: string;
}

const TestimonialSectionCard: React.FC<CardProps> = ({ name, testimonial }) => {
  return (
    <div style={{width: 250}} className="center card-test">
      <div>
        <div className="title-test-card">
          {/* <div className="left-card">
            <img className="img-test" src={imgUrl} alt="doctor" />
          </div> */}
          <div className="right-card">
            <p className="text-test mb-5">
              <CircleCheck /> <span className="verified">Verified</span> 
            </p>
            <p className="title-head color-healthship mb-5">{name}</p>
            <p>
              <Star /> <Star /> <Star /> <Star /> <Star />
            </p>
          </div>
        </div>
        <div
          style={{ minHeight: 40, margin: "10px 20px" }}
          className="text-test"
        >
          {testimonial}
        </div>
      </div>
    </div>
  );
};

export default TestimonialSectionCard;
