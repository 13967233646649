import React, { useState } from "react";
import { AngleDownIcon, AngleUpIcon } from "../icons";

interface FAQProps {
  question: string;
  answer: string;
}

function FAQElement({ question, answer }: FAQProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item">
      <p onClick={toggleAnswer} className="mb-15 f-14">
        <span>{question}</span>
        <span className="faq-right-icon">
        {isOpen && <AngleUpIcon /> }
        {!isOpen && <AngleDownIcon />}          
        </span>
      </p>
      {isOpen && <p className="mb-10 f-14">{answer}</p>}
    </div>
  );
}

export default FAQElement;
