//Axios libray
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem("AuthToken");
  return accessToken || "";
}

function getLocalRefreshToken() {
  const refreshToken = window.localStorage.getItem("RefreshToken");
  return refreshToken;
}

const onRequest = async (config: any) => {
  config.headers = config.headers ?? {};
  const expiryTime = Number(localStorage.getItem("ExpiryTime"));
  const currentTime = new Date().getTime();

  if (getLocalAccessToken() && expiryTime - currentTime < 0) {
    const rs = await axios.post(
      "https://api.healthship.in/user/session/refresh",
      {
        userId: localStorage.getItem("UserId"),
        refresh_token: getLocalRefreshToken(),
      }
    );
    const token = rs.data.data.accessToken;
    const expiryTime = rs.data.data.expirtyTime;

    localStorage.setItem("AuthToken", token);
    localStorage.setItem("ExpiryTime", expiryTime);
  }

  const token = getLocalAccessToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 426) {
      // localStorage.removeItem("AccessToken");
      try {
        const rs = await axios.post(
          "https://api.healthship.in/user/session/refresh",
          {
            refresh_token: getLocalRefreshToken(),
          }
        );

        const { token } = rs.data;

        window.localStorage.setItem("AuthToken", token);

        return;
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
  }

  return Promise.reject(error);
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
};

export const http = setupInterceptorsTo(
  axios.create({
    baseURL: "https://api.healthship.in",
    headers: {
      "Content-Type": "application/json"
    },
  })
);
