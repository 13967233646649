import Footer from "../footer/Footer";
import HeaderComponent from "../header/HeaderComponent";
import "./privacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <HeaderComponent />
      <div className="text-font">
        <h1 className="header-font center my-20">Privacy Policy</h1>
        <div className="outer-padding">
          <div className="content-section">
            <p>
              At Healthship, we are committed to respecting the privacy and
              confidentiality of the information that you entrust us with. Our
              Privacy Policy outlines the policies and procedures regarding the
              collection, use and disclosure of Personal Information from users.
              Please review this Privacy Policy carefully. In order to guarantee
              privacy to the client, we maintain the client’s anonymity and work
              in accordance with confidentiality policies to ensure that all
              personal and health information received is maintained and
              transmitted through a highly secure environment. It is recommended
              that you do not use the website, mobile application(s) or related
              Services if any of the terms of this Privacy Policy are not in
              accordance with the applicable laws of your country.
            </p>

            <h2 className="small-header-font color-healthship mb-10 mt-10">Definition</h2>
            <ul>
              <li>
                <p>
                  Company: Any mention of 'Healthship,' 'healthship.in,'
                  'Company,' 'Firm,' 'we,' 'our,' 'us,' 'Service Provider,' or
                  similar terms refers to Lastunicorn Health Pvt. Ltd., the
                  provider of services herein.
                </p>
              </li>
              <li>
                <p>
                  User: Refers to you, the recipient of our platform's products,
                  services, and resources.
                </p>
              </li>
              <li>
                <p>
                  Platform: Encompasses mobile apps, websites, chatbots, or
                  links facilitating access to Company-provided services.
                </p>
              </li>
              <li>
                <p>
                  Psychological Wellness Professional: Refers to practitioners
                  providing psychological wellness services.
                </p>
              </li>
            </ul>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Nature of Service</h3>
            <p>
              Healthship is a platform offering emotional wellness products and
              services to individuals and organizations. These include, but are
              not limited to, corporate wellness programs, online consultations,
              self-assessments, workshops, self-help tools, and participation in
              user forums.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Consent</h3>
            <p>
              By utilizing the platform, sharing your Personal Information with
              us, or making payments to Healthship, you are expressly consenting
              to the collection, storage, processing, disclosure, and transfer
              of your Personal Information, as outlined in this Privacy Policy
              and in compliance with India's data privacy laws.
            </p>
            <p>
              You acknowledge that the provision of your Personal Information is
              voluntary, either directly to Healthship or through a third-party
              or your organization. You have the choice to refrain from
              providing the requested personal information. Furthermore, you
              have the option to revoke your consent at any time by notifying us
              in writing at care@healthship.in. However, if you access our
              platform via a third-party or your organization, you may withdraw
              your consent by explicitly informing the third party or your
              organization in writing, who will then notify us to take
              appropriate action.
            </p>
            <p>
              Failure to provide us with your Personal Information or
              withdrawing consent may result in us being unable to fulfill the
              intended purposes for which the Personal Information was sought,
              and we may restrict your platform usage accordingly.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Personal Information</h3>
            <p>
              In order to facilitate your interaction with our Services, we may
              utilize personal information about you – provided directly to us,
              through a third party, or by your organization – for
              identification or contact purposes. This may include your name,
              contact details, emergency contact information, gender,
              occupation, hometown, personal interests, email address, reasons
              for appointment cancellations with a healthcare professional,
              medical history, and any other information the Wellness
              Professional may require. Additionally, information collected from
              responses, in-app inputs, assessments, or feedback will be treated
              as Personal Information.
            </p>
            <p>
              The primary objective of collecting this Personal Information is
              to provide you with a seamless, efficient, and personalized
              experience. This information enables users to create and manage
              accounts and profiles for interaction with our wellness
              professionals. You have the capability to modify some of this
              information via your account page on the website or profile
              details within the mobile application(s).
            </p>
            <p>We may use your Personal Information to:</p>
            <ol>
              <li>Identify and reach you;</li>
              <li>
                Resolve service and billing issues via telephone or email.
              </li>
              <li>Aid in scheduling, reminding, or canceling appointments.</li>
              <li>
                Provide additional information, products, services, or
                newsletters.
              </li>
              <li>Better comprehend user requirements and interests.</li>
              <li>
                Conduct anonymous statistical research to enhance services.
              </li>
              <li>
                Detect and safeguard against errors, fraud, or other illicit
                activities.
              </li>
              <li>
                Make necessary disclosures in accordance with applicable laws.
              </li>
              <li>
                Enhance and improve our website and mobile application(s) for
                better service.
              </li>
              <li>Effectively respond to your customer service queries.</li>
              <li>
                Conduct contests, promotions, surveys, or other features on our
                site or app.
              </li>
              <li>Facilitate timely financial transaction processing.</li>
            </ol>
            <p>
              Your information is used by Wellness Professionals and our app
              algorithms to assess your condition and deliver the most suitable
              counseling service or digital experience. In certain instances,
              Healthship and/or Wellness Professionals might use third-party
              tools to customize counseling sessions and in-app experiences,
              sharing only necessary information.
            </p>
            <p>
              We are committed to preserving the confidentiality and integrity
              of your Personal Information. If you wish to stop receiving
              certain communications from us at any time, you can notify us via
              email at care@healthship.in.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Updating Personal Information</h3>
            <p>
              If your Personal Information changes, requires updating,
              correction, or if you have any concerns about its processing or
              usage, you can send updates or corrections to us at
              care@healthship.in. We will make reasonable efforts to incorporate
              the changes within a reasonable timeframe. However, if your
              Personal Information is provided to us through a third-party
              platform, Healthship may be unable to modify it, and you should
              contact the third-party platform for any updates.
            </p>
            <p>
              Should your Personal Information be stored within your platform
              profile, you have the ability to update it via our website or
              mobile application(s).
            </p>
            <p>
              Certain Personal Information, such as your responses to online
              assessments, may be irrevocable once submitted. If you wish to
              have your records removed from our system, please contact us at
              care@healthship.in. We will strive to accommodate your request
              unless legally obligated to retain such information.
            </p>
            <p>
              Kindly note that certain information must be retained in
              compliance with professional standards or legal obligations (such
              as payment history, feedback, client information, etc.).
              Therefore, we will continue to store this data for a specified
              period in accordance with applicable laws, even if you delete your
              account. Additionally, residual information may persist in our
              databases and other records, remaining intact despite our efforts
              to delete it.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Cookies</h3>
            <p>
              We utilize "cookies" to gather information and enhance your
              experience on our platform. A cookie is a small data file
              transferred to your device’s hard disk for record-keeping
              purposes. We employ cookies for two primary purposes. First,
              persistent cookies retain your user credentials for future logins
              to the Services. Second, session ID cookies enable specific
              features of the Services, help us understand your interaction with
              the Services, and monitor aggregate usage and online traffic
              routing. Session cookies are deleted from your computer upon
              logging off from the Services and closing your browser.
            </p>
            <p>
              We may collaborate with third parties to place or read cookies on
              your browser to enhance your user experience. By using third-party
              services via our platform, you consent to their Privacy Policy and
              terms of use, releasing Healthship from liability arising from
              such use.
            </p>
            <p>
              You can adjust your browser settings to decline cookies or prompt
              you before accepting a cookie from visited websites. However,
              refusing cookies may limit your access to some platform features
              or functionalities.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Log Data</h3>
            <p>
              Upon visiting the platform, our servers automatically record
              information your browser or mobile application sends ("Log Data").
              This Log Data includes your computer’s Internet Protocol (“IP”)
              address, browser type, device name, operating system version,
              configuration of the app when accessing the Platform, the webpage
              visited before our Services, pages visited within our platform,
              time spent on those pages, search information on our Services,
              access times and dates, and other statistics. We analyze this data
              to detect trends, administer the site, track location, gather
              broad demographic information for aggregate use, enhance
              user-friendliness, and tailor our Services to suit your needs.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Confidentiality</h3>
            <p>
              Healthship maintains confidentiality of information disclosed
              during personal consultations. Wellness Professionals take
              reasonable precautions to protect confidential information
              obtained or stored in any medium. To ensure high-quality services,
              Healthship requires its Wellness Professionals to participate in
              regular supervision where cases are discussed.
            </p>
            <p>
              In cases involving harm to oneself or others, Wellness
              Professionals are obliged to share information with clinical
              supervisors, emergency contacts, law enforcement, or others
              necessary for safety, notwithstanding legal requirements.
            </p>
            <p>
              Disclosure of criminal activity, including abuse of minors,
              seniors, or disabled individuals, mandates information sharing
              with law enforcement and compliance with legal requirements.
              Clinically significant information may be shared with other
              healthcare professionals upon your consent.
            </p>
            <p>
              Unless otherwise specified, Personal Information shared on
              Healthship requires your oral or written permission before sharing
              it with a third party.
            </p>
            <p>
              The User indemnifies Healthship for any breach in confidentiality
              of their Personal Information. If accessing Healthship through a
              third-party platform, the User indemnifies Healthship against any
              data breaches caused by acts of commission or omission from the
              third-party platform.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Third Party Disclosure</h3>
            <p>
              Healthship does not sell or trade your Personal Information to
              third parties without prior notice, except for necessary storage,
              transfer, or assistance from server/website hosting partners or
              parties aiding platform operation, business conduct, or service
              provision. Your information may be released to comply with the
              law, enforce our policies, or protect rights, property, or safety.
              However, non-personally identifiable visitor information may be
              shared for marketing or advertising purposes.
            </p>
            <h3>Security</h3>
            <p>
              We employ administrative, physical, and technical measures to
              protect information from unauthorized access, use, or disclosure.
              Sessions are not recorded, and Wellness Professionals are required
              to maintain records adhering to professional standards.
            </p>
            <p>
              Despite security measures, avoid posting Personal Information in
              public areas on our platform. Healthship is not liable for misuse
              or issues related to data storage and security.
            </p>
            <p>
              ​Maintain the privacy and security of your email and phone
              messages to prevent third-party access. Healthship will
              communicate through these channels for information regarding
              psychological wellness but is not liable for breaches if accessed
              by third parties.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Links</h3>
            <p>
              The platform may contain links to third-party sites not under
              Healthship's control. Healthship is not responsible for the
              privacy practices of these sites and advises reviewing their
              privacy policies. Accessing third-party sites linked to the
              platform is at your own risk.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Persons Below The Age of 18</h3>
            <p>
              Our Services are not directed at individuals under 18. We do not
              knowingly solicit participation from those under 18.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Changes To Policy</h3>
            <p>
              Healthship reserves the right to change or remove any part of the
              Privacy Policy without notice. Efforts will be made to inform
              users of significant changes. Continued use of the platform after
              being notified of changes constitutes consent to updated terms.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Grievance Redressal</h3>
            <p>
              To address user grievances, Healthship established a Grievance
              Redressal Forum. Dissatisfied users can contact us at
              care@healthship.in
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Jurisdiction</h3>
            <p>
              Disputes arising between users and Healthship regarding this
              Policy are subject to the exclusive jurisdiction of Jodhpuri,
              India's courts, drafted in compliance with Indian laws.
            </p>
            <h3 className="small-header-font color-healthship mb-10 mt-10">Contact Information</h3>
            <p>
              Registered Address: 76, Sardar Club Scheme, Jodhpur, Rajasthan –
              342001
            </p>
            <p>E-mail: care@healthship.in</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
