import { useLocation } from "react-router-dom";
import Assessment from "./Assessment";

const OWB = () => {
  const location = useLocation();
  const url = location.pathname.slice(1);

  return <Assessment page={url} />;
};

export default OWB;
