import React, { PropsWithChildren, useState, useEffect } from "react";
import { loginUser, logoutUser, verifyOtp } from "../services/user-service";

export interface UserContextData {
  id: number;
  name: string;
  email: string;
  mobile: number;
}

export type UserContextType = {
  userData: UserContextData;
  isAuthenticated: boolean;
  saveUserData: (data: any) => void;
  login: (mobile: string, otp: string) => Promise<any>;
  logout: () => void;
};

export const AuthContext = React.createContext<UserContextType | null>(null);

const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState({
    id: 0,
    name: "",
    email: "",
    mobile: 0,
  });
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authToken = localStorage.getItem("AuthToken");
    const userId = localStorage.getItem("UserId");
    if (authToken && userId) {
      loginUser(userId)
        .then((res) => {
          const user = res.data.data;
          setUser({
            id: user.id,
            name: user.name,
            email: user.email,
            mobile: user.mobile,
          });
          setIsAuthenticated(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const login = async (mobile: string, otp: string): Promise<any> => {
    const data = await verifyOtp(mobile, otp);
    if(data) {
      const token = data.data.data.auth.accessToken;
      localStorage.setItem("AuthToken", token);
      const refreshToken = data.data.data.auth.refreshToken;
      localStorage.setItem("RefreshToken", refreshToken);
      const userId = data.data.data.id;
      localStorage.setItem("UserId", userId);
      const expiryTime = data.data.data.auth.expirtyTime;
      localStorage.setItem("ExpiryTime", expiryTime);   
      const userData = await loginUser(userId);
      
      if(userData) {
        setUser({
          id: userData.data.data.id,
          name: userData.data.data.name,
          email: userData.data.data.email,
          mobile: userData.data.data.mobile,
        });
        setIsAuthenticated(true);
      }
      return userData;
    };
  };

  const logout = () => {
    // Remove user information and set authentication status to false
    const refreshToken = localStorage.getItem("RefreshToken");
    if (refreshToken) {
      logoutUser(refreshToken).then((res) => {
        localStorage.removeItem("RefreshToken");
        localStorage.removeItem("AuthToken");
        localStorage.removeItem("UserId");
        localStorage.removeItem("ExpiryTime");
        setUser({
          id: 0,
          name: "",
          email: "",
          mobile: 0,
        });
        setIsAuthenticated(false);
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  const saveUserData = (data: any) => {
    setUser({
      id: data?.id,
      name: data?.name,
      email: data?.email,
      mobile: data?.mobile,
    });

  }

  return (
    <AuthContext.Provider
      value={{ userData: user, isAuthenticated, login, logout, saveUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
