import React, { useEffect, useState } from "react";
import HeaderComponent from "../header/HeaderComponent";
import Footer from "../footer/Footer";
import "./DoctorDetails.css";
import { getCounsellorDataById } from "../../services/counsellor-service";
import { useNavigate } from "react-router";
import LoaderModal from "../loader";
import WhatsappBtn from "../ui/WhatsappBtn";
import { CircleCheck, Star } from "../icons";
import {
  FAQData,
  SpecialisationData,
  TestimonialsData,
  DoctorData
} from "../../data/PageData";
import FAQ from "./Faq";
import Education from "./Education";
import TherapyCarousel from "./TherapyCarousel";
import Specialisation from "./Specialisation";
import CustomerRatings from "./CustomerRatings";
import StyledButton from "../ui/StyledButton/StyledButton";

const DoctorDetails: React.FC = () => {
  const [doctorData, setDoctorData] = useState({
    id: 4,
    name: "",
    email: "",
    mobile: "",
    login_otp: null,
    login_otp_expiry: null,
    profile_picture: "",
    introduction: "",
    experience: "",
    degree: "",
    expertise: "",
    status: "",
    created: "",
    modified: "",
  });
  const [doctorType, setDoctorType] = useState("Counselling Therapist");
  const [isLoading, setIsLoading] = useState(false);
  const [isData, setIsData] = useState(false);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [specData, setSpecData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const arr = window.location.href.split("/");
    setIsLoading(true);
    getCounsellorDataById(arr[arr.length - 1])
      .then((res: any) => {
        setIsLoading(false);
        setIsData(true);
        setDoctorData(res.data.data);
        if(res.data.data.id == "3") {
          setTestimonialsData(DoctorData["3"].testimonials);
          setSpecData(DoctorData[3].specialisation);
        }
        if (res?.data?.data?.counsellor_type == "COUNSELLING") {
          setDoctorType("Counselling Therapist");
        } else {
          setDoctorType("RCI Registered Therapist");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();

  return (
    <div>
      <HeaderComponent />
      {isLoading && <LoaderModal isLoading />}
      {doctorData.id == 3 && (
        <div className="min-dim">
          {!isLoading && isData && (
            <div className="doctor-content">
              {/* General Data Starts Here */}
              <section id="heading">
                <div className="title-card-details">
                  <div className="left-details">
                    <img
                      loading="lazy"
                      className="doctor-img rad-50"
                      src={doctorData.profile_picture}
                      alt="doctor"
                    />
                  </div>
                  <div className="right-details">
                    <p className="small-header-font color-healthship">
                      {doctorData.name}
                      <span className="ml-10">
                        <CircleCheck />
                      </span>
                    </p>
                    <p className="f-14">
                      <span>Consulting Psychologist | </span>
                      <span>{doctorData.experience} years of experience</span>
                    </p>
                    <p className="f-14">
                      <span>80 Sessions | </span>
                      <span>
                        {" "}
                        <Star /> 4.5 |{" "}
                      </span>
                      <span>
                        <CircleCheck /> 40 ratings
                      </span>
                    </p>
                  </div>
                </div>
              </section>
              {/* General Data Ends Here */}
              {/* Education Section Starts Here */}
              <Education />
              {/* Education Section Ends Here */}
              {/* Therapy Details Section Starts Here  */}
              <TherapyCarousel />
              {/* Therapy Details Section Ends Here */}
              {/* Specialisation Section Starts Here */}
              <Specialisation specialisation={specData ? specData: SpecialisationData} />
              {/* Specialisation Section Ends Here */}
              {/* Review Section Starts Here */}
              <CustomerRatings ratingsData={testimonialsData ? testimonialsData : TestimonialsData} />
              {/* Review Section Ends Here */}
              {/* FAQ Section Starts */}
              <FAQ faqData={FAQData} />
              {/* FAQ Section Ends  */}
            </div>
          )}
        </div>
      )}
      {doctorData.id != 3 && (
        <div className="min-dim">
          {!isLoading && isData && (
            <div className="doctor-content-old">
              <div className="mob-img">
                <img
                  width={390}
                  height={300}
                  src={doctorData.profile_picture}
                  alt="doctors"
                />
              </div>
              <div>
                <h1 className="color-healthship header-font">
                  {doctorData.name}
                </h1>
                <p className="my-20 text-font">{doctorData.introduction}</p>
                {/* <div className="center">
                  <StyledButton
                    className="button large"
                    onClick={() => navigate(`/booking?id=${doctorData.id}`)}
                  >
                    Book Appointment
                  </StyledButton>
                </div> */}
              </div>
              <div className="desk-img">
                <img
                  width={300}
                  height={300}
                  src={doctorData.profile_picture}
                  alt="doctors"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <WhatsappBtn />
      <Footer />
      <div className="checkout-btn">
        <button
          onClick={() => {
            navigate(`/booking?id=${doctorData?.id}`);
            localStorage.setItem("DoctorId", doctorData?.id.toString());
          }}
          className="button large mt-10 mb-10"
          type="button"
          id="bookNow"
        >
          <span>Book Session Now</span>
        </button>
      </div>
    </div>
  );
};

export default DoctorDetails;
