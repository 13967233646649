import { useEffect, useState, useRef } from "react";
import HeaderComponent from "../header/HeaderComponent";
import "./Team.css";
import TeamCard from "./TeamCard";
import Footer from "../footer/Footer";
import { getCounsellorsData } from "../../services/counsellor-service";
import LoaderModal from "../loader";
import WhatsappBtn from "../ui/WhatsappBtn";
import CardCarousel from "../how-it-works/CardCarousel";
import TestimonialCardCarousel from "../testimonials-carousel/TestimonialCardCarousel";
import { TestimonialsData } from "../../data/PageData";
import TopBannerDesktop from "../../images/top-banner-desktop.webp";
import TopBannerMobile from "../../images/top-banner-mobile.webp";
import BottomBannerDesktop from "../../images/banner-bottom-desktop.webp";
import BottomBannerMobile from "../../images/banner-bottom-mobile.webp";

interface CardData {
  id: number; // Ensures unique identifier
  title: string;
  content: string;
  step?: string; // Optional image prop
}

const cardData: CardData[] = [
  {
    id: 1,
    title: "Choose a Therapist",
    content: "Pick the therapist that best matches your needs.",
    step: "Step 1",
  },
  {
    id: 2,
    title: "Book a Session",
    content:
      "Choose a package that works for you, select a convenient date & time, and make the payment.",
    step: "Step 2",
  },
  {
    id: 3,
    title: "Invite Your Partner",
    content:
      "Fill in details about your partner and they'll receive an email to let them know about the session.",
    step: "Step 3",
  },
  {
    id: 4,
    title: "Start Therapy",
    content:
      "Attend sessions together and begin the journey towards a stronger relationship.",
    step: "Step 4",
  },
];

const Team: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isData, setIsData] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const [counsellorData, setCounsellorData] = useState([
    {
      id: 1,
      name: "",
      email: "",
      mobile: "",
      login_otp: null,
      login_otp_expiry: null,
      profile_picture: "",
      experience: "",
      expertise: "",
      status: "",
      created: "",
      modified: "",
      bestsellers: "",
      counsellor_type: "",
      min_price: ""
    },
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);
    getCounsellorsData()
      .then((res: any) => {
        setIsLoading(false);
        setIsData(true);
        setCounsellorData(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const bannerClick = () => {
    window.location.href = "https://wa.me/918076200720?text=Hi%20There!";
  };

  const handleTopBannerClick = () => {
    const gridElement = gridRef.current;
    if (gridElement) {
      gridElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <div>
        <HeaderComponent />
        <LoaderModal isLoading={isLoading} />
        <div ref={bannerRef} onClick={handleTopBannerClick} className="pointer">
          <img className="not-found" src={TopBannerDesktop} />
          <img
            height={350}
            className="not-found-mobile img-height"
            src={TopBannerMobile}
          />
        </div>
        <div ref={gridRef} className="teams-content min-dim">
          <h1 className="header-font color-healthship mt-20 mx-50 center">
            Talk To Mental Health Experts Now
          </h1>
          {isData && !isLoading && (
            <div className="grid mt-20">
              {counsellorData.map((item, index) => (
                <TeamCard
                  key={index}
                  name={item.name}
                  id={item.id}
                  imgUrl={item.profile_picture}
                  experience={item.experience}
                  expertise={item.expertise}
                  min_price={item.min_price}
                  counsellor_type={item.counsellor_type}
                  bestsellers={item.bestsellers}
                />
              ))}
            </div>
          )}
        </div>
        <div className="mt-50">
          <h2 className="header-font color-healthship center ph-50">
            How Therapy at Healthship Works
          </h2>
          <CardCarousel cards={cardData} />
        </div>
        <div className="mt-50">
          <h2 className="header-font center mb-20 mt-10 color-healthship">
             Our Customers Speak
          </h2>
          <TestimonialCardCarousel cards={TestimonialsData} />
        </div>
        <div className="pointer">
          <img
            className="not-found"
            onClick={bannerClick}
            src={BottomBannerDesktop}
          />
          <img
            className="not-found-mobile"
            onClick={bannerClick}
            src={BottomBannerMobile}
          />
        </div>
        <WhatsappBtn />
        <Footer />
      </div>
    </>
  );
};

export default Team;
