import React, { useEffect, useState } from "react";
import "./login.css";
import HeaderComponent from "../header/HeaderComponent";
import Footer from "../footer/Footer";
import OtpScreen from "./otpScreen";
import "react-toastify/dist/ReactToastify.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { sendOtp } from "../../services/user-service";
import LoaderModal from "../loader";
import StyledButton from "../ui/StyledButton/StyledButton";
import { toastFn } from "../../utils/helpers";

type FormValues = {
  mobile: string;
};

const Login: React.FC = () => {
  const [mobile, setMobile] = useState("1234567890");
  const [isLoginScreen, setIsLoginScreen] = useState(true);
  const [url, setUrl] = useState("/");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ criteriaMode: "all" });
  const [loading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const redirectionUrl = queryParameters.get("redirection");
    if (redirectionUrl) setUrl("/" + redirectionUrl);
  }, []);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setIsLoading(true);
    sendOtp(data.mobile)
      .then((res) => {
        setIsLoading(false);
        if (res) {
          setMobile(data.mobile);
          toastFn("OTP sent successfully");
          setIsLoginScreen(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleNumberChange = (event: any) => {
    const value = event.target.value;
    if (/[^0-9]/.test(value)) {
      event.target.value = value.replace(/[^0-9]/g, "");
    }
  };

  const handleKeyDown = (event: any) => {
    // If the user is pressing the backspace or delete key, allow the key press.
    if (event.key === "Backspace" || event.key === "Delete") {
      return;
    }

    // If the user is entering a digit, prevent the key press if the input value is already 10 digits long.
    if (/\d/.test(event.key) && event.target.value.length === 10) {
      event.preventDefault();
    }
  };

  return (
    <>
      <HeaderComponent />
      <div className="login-page min-dim center">
        <div
          className={
            isLoginScreen ? "send-otp-section active" : "send-otp-section"
          }
          id="login-otp-step"
        >
          <h1 className="small-header-font color-healthship">
            Sign Up or Login with OTP
          </h1>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <input
              className="mobile text-font"
              type="number"
              {...register("mobile", {
                required: "Mobile number is required",
                minLength: {
                  value: 10,
                  message: "Mobile number should have 10 digits",
                },
                maxLength: {
                  value: 10,
                  message: "Mobile number should have 10 digits",
                },
                pattern: {
                  value: /^[0-9]+$/,
                  message: "Please enter a number.",
                },
              })}
              placeholder="Enter your mobile Number"
              onChange={handleNumberChange}
              onKeyDown={handleKeyDown}
            />
            <div className="error-message">
              {errors.mobile && errors.mobile.message}
            </div>
            <StyledButton className="button large" type="submit">
              <span>Send OTP</span>
              <LoaderModal isLoading={loading} />
            </StyledButton>
            <div className="ml-20 mt-20">
              <span className="text-font">
                {" "}
                <span>I agree to the</span>{" "}
                <span
                  onClick={() => navigate("/terms")}
                  className="pointer ml-5"
                >
                  terms & conditions
                </span>{" "}
                <span className="ml-5">and</span>{" "}
                <span
                  onClick={() => navigate("/privacy-policy")}
                  className="pointer ml-5"
                >
                  privacy policy
                </span>{" "}
              </span>
            </div>
          </form>
        </div>
        <div
          className={
            isLoginScreen ? "verify-otp-section" : "verify-otp-section active"
          }
          id="verify-otp-step"
        >
          <OtpScreen mobile={mobile} redirectionUrl={url} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
