import { http } from "../utils/http-common";

const sendOtp = async (mobile: string) => {
  try {
    const { data, status } = await http.post(
      "/user/otp/send",
      {
        mobile: mobile,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    return data;
  } catch (error) {}
};

const verifyNumber = async (mobile: string) => {
  try {
    const { data, status } = await http.post(
      "/user/get/details",
      {
        mobile: mobile,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer XCgy9WCfQEs5ONiU9Rj4ocqMTSMh1TgHWLRv3wqabIJCyVGXiwClvtOaKCrtR6LY"
        },
      }
    );

    return data;
  } catch (error) {}
};

const verifyOtp = (mobile: string, otp: string) => {
  return http.post(
    "/user/otp/verify",
    {
      mobile: mobile,
      otp: otp,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
};

const loginUser = (userid: string) => {
  return http.get(`/user/${userid}`);
};

const logoutUser = (refresh_token: string) => {
  return http.put("/user/logout", {
    refresh_token: refresh_token,
  });
};

const fetchOrderHistory = (userId: string) => {
  return http.get('/session/booking/history', {
    data: {
      user_id: userId
    }
  })
}

const refreshToken = (refresh_token: string) => {
  return http.post("/user/session/refresh", {
    refresh_token: refresh_token,
  })
}

const updateUser = (userData: any) => {
  return http.put(`/user/${userData.id}`, {
    id: userData.id,
    name: userData.name,
    email: userData.email,
    languages: userData.languages
  })
}

export { verifyOtp, verifyNumber, loginUser, sendOtp, logoutUser, refreshToken, updateUser, fetchOrderHistory };
