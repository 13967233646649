import React, { useState, useEffect } from "react";
import "./Assessment.css";
import HeaderComponent from "../header/HeaderComponent";
import Footer from "../footer/Footer";
import { AssessmentData } from "../../data/PageData";
import { Helmet } from "react-helmet";
import { Widget } from "@typeform/embed-react";
import WhatsappBtn from "../ui/WhatsappBtn";

const Assessment = ({ page }: any) => {
  const [assessmentData, setAssessmentData] = useState({
    typeform:"rrPrqeWO",
    jotLink: "https://form.jotform.com/232321174771451",
    seoTitle: "Mental Well Being Assessment - Healthship",
    seoDesc:
      "Take our Mental Well-Being assessment based on the Ryff Scales of Psychological Well-Being to understand your mental well-being and consult our therapists for more help.",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    if (page) {
      setAssessmentData(AssessmentData[page]);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>{assessmentData.seoTitle}</title>
        <meta name="description" content={assessmentData.seoDesc} />
      </Helmet>
      <HeaderComponent />
      <Widget id={assessmentData.typeform} style={{ height: 600 }} className="my-form" />
      {/* <div className="assessment-page center">
        <iframe
          allowFullScreen={true}
          style={{ width: "100%", height: "500px" }}
          title="your title"
          src={assessmentData.jotLink}
        ></iframe>
      </div> */}
      <WhatsappBtn />
      <Footer />
    </div>
  );
};

export default Assessment;
