import { TestimonialsData } from "../../data/PageData";
import { HalfStar, Star } from "../icons";
import MultiCarousel from "../multi-carousel/MultiCarousel";
import TestimonialCardCarousel from "../testimonials-carousel/TestimonialCardCarousel";

interface CustomerRatingsProps {
  ratingsData: any;
}

const CustomerRatings = ({ ratingsData }: CustomerRatingsProps) => {
  return (
    <section className="reviews">
      <h2 className="mb-10 mt-10">Customer Ratings</h2>
      <div className="mb-10">
        <p>
          <span className="mb-5">
            {" "}
            <Star /> <Star /> <Star /> <Star /> <HalfStar />{" "}
          </span>
          <span className="text-font">4.5 out of 5</span>
        </p>
        <p>40 Ratings</p>
      </div>
      {/* Add a section with stars and numerical ratings */}
      <h2 className="mb-10">Customers Say</h2>
      <p className="text-font mb-10">
        Customers appreciate the way therapists are able to help them open up,
        and also create a safe and non-judgemental space. They also like how the
        therapists are able to provide them with a path to help them find
        solutions to their problems and change their lives.
      </p>
      <div>
        <TestimonialCardCarousel cards={ratingsData} />
      </div>
      {/* <div className="desk-display">
        {" "}
        <MultiCarousel
          carouselVal="isRatings"
          carouselData={TestimonialsData}
        />{" "}
      </div> */}
    </section>
  );
};

export default CustomerRatings;
