import { useNavigate } from "react-router-dom";
import StyledButton from "../components/ui/StyledButton/StyledButton";

const FallbackComponent = () => {

  const navigate = useNavigate();

  return (
    <div>
      <p>Something went wrong</p>
      <StyledButton onClick={() => navigate("/")} className="button large">Go To Home</StyledButton>
    </div>
  );
};

export default FallbackComponent;
