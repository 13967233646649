export const TakeAssessmentList = ["Overall Well Being", "Anxiety", "Career Stress", "Depression", "ADHD"]


export const TakeAssessmentData: any = {
    "Overall Well Being": {
        "imgUrl": "",
        "title": "Overall Well Being",
        "desc": "Take this assessment to know more about your psychological well-being and get matched to the right counsellor according to your needs.",
        "time": "5 min",
        "redirectionUrl": "/overall-well-being"
    },
    "Anxiety": {
        "imgUrl": "",
        "title": "Anxiety",
        "desc": "Feeling anxious or overwhelmed? Take this assessment to understand if your symptoms relate to Generalised Anxiety Disorder.",
        "time": "1 min 30 seconds",
        "redirectionUrl": "/anxiety"
    },
    "Career Stress": {
        "imgUrl": "",
        "title": "Career Stress",
        "desc": "Not sure about your job? Take this assessment and understand how your job stress is affecting your personal and work life.",
        "time": "5 min",
        "redirectionUrl": "/career-stress"
    },
    "Depression": {
        "imgUrl": "",
        "title": "Depression",
        "desc": "Feeling sad all the time? Take this assessment to understand if you are going through depression.",
        "time": "1 min 30 seconds",
        "redirectionUrl": "/depression"
    },
    "ADHD": {
        "imgUrl": "",
        "title": "ADHD",
        "desc": "Not able to finish up work or having problems concentrating? Take this assessment to understand if you have symptoms related to ADHD.",
        "time": "5 min",
        "redirectionUrl": "/adhd"
    }

}


export const AssessmentData = [
    {
        "imgUrl": "assessment.webp",
        "title": "Take an Assessment",
        "desc": "Take an assessment and get to know about your symptoms."
    },
    {
        "imgUrl": "doctor-match.webp",
        "title": "Get Matched with an Expert",
        "desc": "Get matched with an expert according to your needs."
    },
    {
        "imgUrl": "discuss-concerns.webp",
        "title": "Discuss your concerns",
        "desc": "Open up about your concerns to your expert and get help."
    },
    {
        "imgUrl": "improvement.webp",
        "title": "Unleash a better self",
        "desc": "Bid goodbye to your old self and unleash a new self."
    }
]