import React, { useEffect, useState } from "react";
import "./Team.css";
import { useNavigate } from "react-router-dom";
import StyledButton from "../ui/StyledButton/StyledButton";
import { CircleCheck } from "../icons";
import { DoctorData } from "../../data/PageData";

interface CardProps {
  name: string;
  id: number;
  imgUrl: string;
  experience: string;
  expertise: string;
  min_price: string;
  counsellor_type: string;
  bestsellers: string;
}

const TeamCard: React.FC<CardProps> = ({
  name,
  id,
  imgUrl,
  experience,
  expertise,
  min_price,
  counsellor_type,
  bestsellers,
}) => {
  const navigate = useNavigate();
  const [exp, setExp] = useState<string[]>([]);
  const [doctorType, setDoctorType] = useState("");
  const [sessionTime, setSessionTime] = useState("");
  const [lang, setLang] = useState("Hindi, English")
  useEffect(() => {
    const arr = expertise.split(",");
    setExp(arr);
  }, [expertise]);

  useEffect(() => {
    const doctorObj = DoctorData[id];
    if (doctorObj) {
      setDoctorType(doctorObj?.doctorType);
      setSessionTime(doctorObj?.sessionTime);
      setLang(doctorObj?.language);
    }
  }, []);

  return (
    <div className="team-card rad-10 mb-50 mr-20">
      {bestsellers && <div className="top-choice">{bestsellers}</div>}
      <div className="title-card">
        <div className="left">
          <img
            loading="lazy"
            className="doctor-img"
            src={imgUrl}
            alt="doctor"
          />
        </div>
        <div className="right left">
          <p className="small-header-font color-healthship">
            {name}
            <span className="ml-10">
              <CircleCheck />
            </span>
          </p>
          <p className="text-font">{experience} + years of experience</p>
          <p className="text-font therapy-type">{doctorType}</p>
        </div>
      </div>
      <div className="content">
        <div className="text-font mx-10 left">
          <span style={{ color: "gray", marginRight: 2 }}>Expertise:</span>
          {exp.map((e, index) => (
            <span className={index == 0 ? "first-exp" : "second-exp"}>{e}</span>
          ))}
        </div>
        <div className="text-font m-10 left">
          <span style={{ color: "gray" }}>Speaks:</span>{" "}
          <span>{lang}</span>
        </div>
      </div>
      <div style={{ backgroundColor: "whitesmoke" }}>
        <div className="pricing-sec text-font p-10 ml-10 left">
          <span style={{ marginRight: 5, fontWeight: 300 }}>
            Starts @
          </span>
          <span style={{ color: "#08831a", fontWeight: 700 }}>₹{min_price}</span>
          <span className="ml-5">for {sessionTime} mins</span>
        </div>
        <div className="p-10 flex">
          <StyledButton
            className="button view-btn small w-50"
            data-name={name}
            onClick={() => {
              navigate("/online-therapist-psychiatrist/" + id);
              localStorage.setItem("DoctorId", id.toString());
            }}
          >
            View Bio
          </StyledButton>
          <StyledButton
            className="button details-btn small w-50"
            data-name={name}
            onClick={() => {
              navigate(`/booking?id=${id}`);
              localStorage.setItem("DoctorId", id.toString());
            }}
          >
            Book Session
          </StyledButton>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
