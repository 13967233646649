import { useEffect, useState } from "react";
import { getCounsellorDataById } from "../../services/counsellor-service";

interface CardProps {
  id: string;
}

const TherapistCard = ({ id }: CardProps) => {
  const [doctorData, setDoctorData] = useState({
    id: 4,
    name: "",
    email: "",
    mobile: "",
    login_otp: null,
    login_otp_expiry: null,
    profile_picture: "",
    introduction: "",
    experience: "",
    degree: "",
    expertise: "",
    status: "",
    created: "",
    modified: "",
  });

  useEffect(() => {
    if (id !== "0") {
      console.log(id);
      getCounsellorDataById(id)
        .then((res: any) => {
          setDoctorData(res.data.data);
        })
        .catch((err) => console.log(err));
    }
  }, []);
  return (
    <div className="flex card-main">
      <div>
        <img width={80} height={80} src={doctorData.profile_picture} />
      </div>
      <div className="ml-10 white">
        <h2 className="small-header-font mb-10">{doctorData.name}</h2>
        <p className="small-text-font">
          <span className="mr-10">{doctorData.experience} yrs exp</span>
          <span>{doctorData.degree}</span>
        </p>
      </div>
    </div>
  );
};

export default TherapistCard;
