import React from "react";
import "./Home.css";
import Card from "./Card";
import { useNavigate } from "react-router-dom";
import { ArrowRightIcon } from "../icons";
import StyledButton from "../ui/StyledButton/StyledButton";

const data = [
  {
    img: "Growth.webp",
    title: "Growth",
    desc: "Our Therapists help you grow and discover a new self",
  },
  {
    img: "doctor-matching.webp",
    title: "Doctor Matching",
    desc: "We connect you to the right doctor as per your need.",
  },
  {
    img: "wherever-whenever.webp",
    title: " Whenever Wherever",
    desc: "We provide on-demand care and flexibility to our patients to complete their visits anytime.",
  },
  {
    img: "personalized-support.webp",
    title: "Personalized Support",
    desc: "Our treatment is tailored to your needs, so you get the right support at the right time.",
  },
  {
    img: "no-judgement.webp",
    title: "No Judgement",
    desc: "We provide a judgement-free zone for everyone to unwind ",
  },
  {
    img: "ongoing-support.webp",
    title: "Easy ongoing care",
    desc: "Our platform makes follow-ups easy and seamless for you.",
  },
];

const PotentialSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <section className="unlock section">
      <h1 className="header-font color-healthship">Unlock your true potential with us</h1>
      <div className="grid-container">
        {data.map((item, index) => (
          <Card
            key={index}
            index={index}
            imgUrl={item.img}
            title={item.title}
            desc={item.desc}
          />
        ))}
      </div>
      <StyledButton
        className="button large"
        type="button"
        onClick={() => navigate("/team")}
      >
        <span>Get Started Now</span>
        <span>
          <ArrowRightIcon />
        </span>
      </StyledButton>
    </section>
  );
};

export default PotentialSection;
