import React from "react";
import NotFound from "../images/404 page for desktop2.webp";
import NotFoundMobile from "../images/404 mobile.webp";
import StyledButton from "./ui/StyledButton/StyledButton";
import { useNavigate } from "react-router-dom";

const FourZeroFour: React.FC = () => {

    const navigate = useNavigate();

  return (
    <>
      <div>
        <img width={1038} height={400} style={{ minWidth: "1038", height: "400" }} className="not-found" src={NotFound} />
        <img width={400} height={400} style={{ minWidth: "400", height: "400" }} className="not-found-mobile" src={NotFoundMobile} />
      </div>

      <div className="mt-20 center">
        <p className="text-font"> Looks like you are lost</p>
        <p>The Page you are looking for is not available</p>
        <StyledButton onClick={() => navigate("/")} className="button large mt-10">Go To Home</StyledButton>
      </div>
    </>
  );
};

export default FourZeroFour;
