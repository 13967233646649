// ImageGrid.tsx
import React from "react";
import "./ImageGrid.css";
import Sample1 from "../../images/Woman1.webp";
import TopBannerMobile from "../../images/top-banner-mobile.webp";
import Sample2 from "../../images/Woman2.webp";
import Sample3 from "../../images/Woman3.webp";
import Sample4 from "../../images/Woman4.webp";
import Sample5 from "../../images/Woman5.webp";

interface ImageProps {
  isMobile: boolean;
}

const ImageGrid: React.FC<ImageProps> = ({ isMobile }) => {

  return (
    <div>
      <div className="image mobile">
        <img width={390} height={270} src={TopBannerMobile} alt="Sample 1" />
      </div>
      {!isMobile && <div className="image-grid">
       <div>
        <div className="row mobile-grid">
        <div className="image large-img">
          <img src={Sample1} alt="Sample 1" />
        </div>
        <div className="image">
          <img src={Sample2} alt="Sample 2" />
        </div>
      </div>
      <div className="row mobile-grid">
        <div className="image">
          <img src={Sample3} alt="Sample 3" />
        </div>
        <div className="image">
          <img src={Sample4} alt="Sample 4" />
        </div>
        <div className="image">
          <img src={Sample5} alt="Sample 5" />
        </div>
      </div>        
        </div>
    </div>}
    </div>
  );
};

export default ImageGrid;
