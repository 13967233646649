import { sendOtp, verifyOtp, loginUser } from "../../services/user-service";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import "./login.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import LoaderModal from "../loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext, UserContextType } from "../../context/authContext";
import StyledButton from "../ui/StyledButton/StyledButton";
import { toastFn } from "../../utils/helpers";

type FormValues = {
  otp: any;
};

interface OtpProps {
  mobile: string;
  redirectionUrl: string;
}

const OtpScreen = ({ mobile, redirectionUrl }: OtpProps) => {
  const {
    register: otpRegister,
    handleSubmit: submitOtp,
    formState: { errors },
  } = useForm<FormValues>({ criteriaMode: "all" });
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useContext(AuthContext) as UserContextType;

  const navigate = useNavigate();

  // const [navigationUrl, setNavigationUrl] = useState("/");

  // useEffect(() => {
  //   const queryParameters = new URLSearchParams(window.location.search);
  //   const redirection = queryParameters.get("redirection");
  //   if(redirection != null) {
  //     setNavigationUrl("/" + redirection);
  //   }
  // }, []);

  const resendOTP = () => {
    sendOtp(mobile)
      .then((res) => {
        toastFn("OTP sent successfully");
      })
      .catch((error) => {
        toastFn("Please try again");
        console.log(error);
      });
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (mobile != null) {
      setIsLoading(true);
      login(mobile, data.otp)
        .then((res) => {
          setIsLoading(false);
          toastFn("User logged in successfully");
          //Add navigation to the previous screen here
          navigate(redirectionUrl);
          setIsLoading(false);
        })
        .catch((error) => {
          toast.error("Please Enter a valid OTP", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  const handleNumberChange = (event: any) => {
    const value = event.target.value;
    if (/[^0-9]/.test(value)) {
      event.target.value = value.replace(/[^0-9]/g, "");
    }
  };

  const handleKeyDown = (event: any) => {
    // If the user is pressing the backspace or delete key, allow the key press.
    if (event.key === "Backspace" || event.key === "Delete") {
      return;
    }

    // If the user is entering a digit, prevent the key press if the input value is already 10 digits long.
    if (/\d/.test(event.key) && event.target.value.length === 6) {
      event.preventDefault();
    }
  };

  return (
    <div className="otp-page">
      <form className="form" onSubmit={submitOtp(onSubmit)}>
        <label className="otp-input center header-font color-healthship">
          Enter the OTP
        </label>
        <input
          className="otp"
          {...otpRegister("otp", {
            required: "Enter an OTP",
            minLength: {
              value: 6,
              message: "OTP should be of 6 digits",
            },
            maxLength: {
              value: 6,
              message: "OTP should be of 6 digits",
            },
          })}
          onChange={handleNumberChange}
          onKeyDown={handleKeyDown}
        />
        {/* {errors.otp && <div>{errors.otp.message}</div>} */}
        <ErrorMessage
          errors={errors}
          name="otp"
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p className="error-message" key={type}>
                {message}
              </p>
            ))
          }
        />
        <StyledButton type="submit" className="button large">
          <span>Verify OTP</span>
        </StyledButton>
        <div className="pointer mt-10 text-font" onClick={() => resendOTP()}>
          Resend OTP
        </div>
        <LoaderModal isLoading={isLoading} />
      </form>
    </div>
  );
};

export default OtpScreen;
