import React, { useContext, useEffect, useState, Fragment } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import HeaderComponent from "../header/HeaderComponent";
import "./Checkout.css";
import { useNavigate } from "react-router-dom";
import { getCounsellorSessionDataById } from "../../services/counsellor-service";
import { AuthContext, UserContextType } from "../../context/authContext";
import Select, { components } from "react-select";
import OtpModal from "./OtpModal";
import StyledButton from "../ui/StyledButton/StyledButton";
import { Helmet } from "react-helmet";
import TherapistCard from "./TopTherapistCard";
import { AngleDownIcon, AngleUpIcon } from "../icons";
import { processToPayment } from "../../services/checkout-service";
import { verifyNumber } from "../../services/user-service";
import { TestimonialsData } from "../../data/PageData";
import TestimonialCardCarousel from "../testimonials-carousel/TestimonialCardCarousel";

type FormValues = {
  mobileNumber: number;
  plans: any;
};

const Option = (props: any) => {
  return (
    <Fragment>
      <components.Option {...props}>
        <div className="text-font" style={{ display: "flex" }}>
          <span>{props.data.name}</span>
          <div className="pricing-box">
            <span className="original-price mr-10">{props.data.price}</span>
            <span className="discounted-price mr-10">
              {props.data.discountedPrice}
            </span>
          </div>
        </div>
      </components.Option>
    </Fragment>
  );
};

export default function Checkout() {
  const queryParameters = new URLSearchParams(window.location.search);
  var docId = queryParameters.get("id");

  const [sessionOptions, setSessionOptions] = useState([
    {
      duration: "",
      session_id: 1,
      name: "",
      price: 0,
      slashed_price: 0,
      percentage: "",
      therapy_type: "",
    },
  ]);
  const [billData, setBillData] = useState({
    name: "",
    price: 0,
    slashed_price: 0,
    total_price: 0,
    gst: 0,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    criteriaMode: "all",
  });
  const [mobile, setMobile] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const { isAuthenticated, userData } = useContext(
    AuthContext
  ) as UserContextType;
  const navigate = useNavigate();
  const [isWhatsappChecked, setIsWhatsappChecked] = useState(true);
  const [openBillSection, setOpenBillSection] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const doctorId = localStorage.getItem("DoctorId");
    if (docId !== null) {
      localStorage.setItem("DoctorId", docId);
    } else if (doctorId && !docId) {
      navigate(`/booking?id=${doctorId}`);
      docId = doctorId;
    } else {
      navigate('/booking?id=3');
      docId = "3";
    }

    if (userData && userData.mobile) {
      setMobile(userData.mobile.toString());
      setValue("mobileNumber", userData.mobile);
    }

    //Set Default Price Logic Goes here - need to get data from the API
    getCounsellorSessionDataById(docId)
      .then((res: any) => {
        const counsellorData = res.data.data;
        const optionData: any = [];
        counsellorData.forEach((c: any) => {
          let percent: any = (
            100 -
            (c.slashed_price / c.price) * 100
          ).toFixed();
          optionData.push({
            session_id: c.session_id,
            name: c.name,
            price: c.price,
            slashed_price: c.slashed_price,
            percentage: percent + "%",
            therapy_type: c.therapy_type,
            duration: c.duration,
          });
        });
        setValue("plans", optionData[0].session_id.toString());
        const gst = (optionData[0].price * (18 / 100)).toFixed(2);
        setBillData({
          name: optionData[0].name,
          price: optionData[0].price,
          slashed_price: optionData[0].price,
          total_price: (optionData[0].price + Number(gst)).toFixed(2),
          gst: Number(gst),
        });
        setSessionOptions(optionData);
      })
      .catch((error) => console.log(error));
  }, [userData]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const sesData = sessionOptions.filter(
      (session: any) => session.session_id == data.plans
    );

    localStorage.setItem("SessionId", sesData[0].session_id.toString());
    localStorage.setItem("Whatsapp", isWhatsappChecked.toString());
    localStorage.setItem("SessionTime", sesData[0].duration.toString());

    if (isAuthenticated) {
      processToPayment({
        sessionId: Number(sessionOptions[0].session_id),
        bookingDate: "2024-04-04",
        bookingTime: "03:30:30",
        message: "No Message Recieved",
        sessionPrice: Number(billData.total_price),
        callbackUrl: "https://healthship.in/order-status",
        whatsappSubscription: isWhatsappChecked,
      })
        .then((res) => {
          const redirectionUrl = res.data.data.url;
          window.location.href = redirectionUrl;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      verifyNumber(data.mobileNumber.toString())
        .then((data) => {
          console.log(data);
          if (data && data.message == "Data fetched successfully") {
            const token = data.data.auth.accessToken;
            localStorage.setItem("AuthToken", token);
            const refreshToken = data.data.auth.refreshToken;
            localStorage.setItem("RefreshToken", refreshToken);
            const userId = data.data.id;
            localStorage.setItem("UserId", userId);
            const expiryTime = data.data.auth.expirtyTime;
            localStorage.setItem("ExpiryTime", expiryTime);

            processToPayment({
              sessionId: Number(sessionOptions[0].session_id),
              bookingDate: "2024-04-04",
              bookingTime: "03:30:30",
              message: "No Message Recieved",
              sessionPrice: Number(billData.total_price),
              callbackUrl: "https://healthship.in/order-status",
              whatsappSubscription: isWhatsappChecked,
            })
              .then((res) => {
                const redirectionUrl = res.data.data.url;
                window.location.href = redirectionUrl;
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleNumberChange = (event: any) => {
    const value = event.target.value;
    if (/[^0-9]/.test(value)) {
      event.target.value = value.replace(/[^0-9]/g, "");
    }
    if (/\d/.test(event.key) && event.target.value.length === 10) {
      event.preventDefault();
    }
    setMobile(event.target.value);
  };

  const handleKeyDown = (event: any, length: number) => {
    // If the user is pressing the backspace or delete key, allow the key press.
    if (event.key === "Backspace" || event.key === "Delete") {
      return;
    }

    // If the user is entering a digit, prevent the key press if the input value is already 10 digits long.
    if (/\d/.test(event.key) && event.target.value.length === length) {
      event.preventDefault();
    }
  };

  const handleCheckoutState = async () => {
    setIsOtpSent(false);
    navigate("/calendly");
  };

  const handleIsOtp = () => {
    setIsOtpSent(false);
  };

  return (
    <div>
      <Helmet>
        <title>Book Online | Healthship</title>
        <meta
          name="description"
          content="Book a session with our expert therapists to improve your mental health."
        />
      </Helmet>
      <HeaderComponent />
      <div className="page">
        <h1 className="header-font color-healthship checkout-heading py-10 center">
          Confirm Booking
        </h1>
        <TherapistCard id={docId || ""} />
        <div id="step1">
          <div className="section-left">
            <form className="form-data" onSubmit={handleSubmit(onSubmit)}>
              {/* <p className="my-10 header-font">Tell us a bit about yourself</p>
              {!isAuthenticated && (
                <p className="already-text my-10 text-font">
                  Already have an account?{" "}
                  <span
                    className="color-healthship pointer"
                    onClick={() =>
                      navigate("/login?redirection=booking?id=" + doctorId)
                    }
                  >
                    Log In
                  </span>{" "}
                  for faster booking.
                </p>
              )}
              <div className="form-element">
                <input
                  type="text"
                  placeholder="Full Name*"
                  {...register("fullName", {
                    required: "Full Name is required",
                    maxLength: {
                      value: 80,
                      message: "The Name cannot exceed 80 characters",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="fullName"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p
                        className="text-font color-healthship mb-10"
                        key={type}
                      >
                        {message}
                      </p>
                    ))
                  }
                />
              </div>
              <div className="form-element">
                <input
                  type="text"
                  placeholder="Email*"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Email is not valid",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p
                        className="text-font color-healthship mb-10"
                        key={type}
                      >
                        {message}
                      </p>
                    ))
                  }
                />
              </div> */}
              <div className="form-element input-width">
                <label className="text-font block left my-10">
                  Enter Your Mobile Number *
                </label>
                <input
                  type="tel"
                  placeholder="Mobile number*"
                  disabled={isAuthenticated}
                  {...register("mobileNumber", {
                    required: "Mobile number is required",
                    minLength: {
                      value: 10,
                      message: "Mobile number should have 10 digits",
                    },
                    maxLength: {
                      value: 10,
                      message: "Mobile number should have 10 digits",
                    },
                    pattern: {
                      value: /^[0-9]+$/,
                      message: "Please enter a number.",
                    },
                  })}
                  onChange={handleNumberChange}
                  onKeyDown={(e) => {
                    handleKeyDown(e, 10);
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="mobileNumber"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <p
                        className="text-font color-healthship mb-10"
                        key={type}
                      >
                        {message}
                      </p>
                    ))
                  }
                />
              </div>

              {/* <div className="form-element input-width">
                <label className="block text-font">
                  Select Number of Sessions
                </label>
                <Controller
                  name="plans"
                  control={control}
                  render={({ field }) => (
                    <Select
                      isSearchable={false}
                      {...field}
                      styles={{
                        control: (baseStyles: any) => ({
                          ...baseStyles,
                          padding: "10px 0",
                          fontSize: "14px",
                          marginBottom: "20px",
                          border: "1px solid black",
                          borderRadius: "3px",
                        }),
                      }}
                      options={sessionOptions}
                      defaultValue={sessionOptions[0]}
                      formatOptionLabel={(data) => {
                        return (
                          <div
                            className="text-font"
                            style={{ display: "flex" }}
                          >
                            <span>{data.name}</span>
                            <div className="pricing-box">
                              <span className="original-price mr-10">
                                {data.price}
                              </span>
                              <span className="discounted-price mr-10">
                                {data.discountedPrice}
                              </span>
                            </div>
                          </div>
                        );
                      }}
                      components={{ Option }}
                      getOptionValue={(option) => option.id}
                    />
                  )}
                />
              </div> */}
              <div className="form-element input-width">
                <label className="block text-font">
                  Select Number of Sessions
                </label>

                {sessionOptions.map((option, index) => (
                  <div
                    className="text-font mb-10 pb-5"
                    style={{ borderBottom: "1px black solid" }}
                    key={index}
                  >
                    <label className="mb-10" style={{ color: "gray" }}>
                      {option.name == "1 session"
                        ? "Get a Single Session"
                        : "Get a Package"}
                    </label>
                    <div className="flex py-10">
                      {option.name} ({option.duration} mins)
                      <span className="ml-auto">
                        <span className="original-price mr-10">
                          Rs {option.slashed_price}
                        </span>
                        <span className="discounted-price">
                          Rs {option.price}
                        </span>
                      </span>
                      <input
                        {...register("plans")}
                        className="flex"
                        style={{ marginLeft: 5, width: 16, height: 16 }}
                        type="radio"
                        onChange={() => {
                          const gst = (
                            option.price *
                            (18 / 100)
                          ).toFixed(2);
                          setBillData({
                            name: option.name,
                            price: option.price,
                            slashed_price: option.slashed_price,
                            total_price: Number((option.price + Number(gst)).toFixed(2)),
                            gst: Number(gst),
                          });
                        }}
                        value={option.session_id}
                        id="field-plans"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <section
                style={{ fontSize: 12 }}
                className="bottom-widget p-10 mb-10"
              >
                <div className="white">Don’t Miss out, Book Now.</div>
                <div className="white">
                  You’ll be asked to select the date and time of your session
                  post-payment.
                </div>
              </section>

              <div style={{ width: "80%" }} className="m-auto">
                <input
                  className="mr-10"
                  checked={isWhatsappChecked}
                  onChange={() => setIsWhatsappChecked(!isWhatsappChecked)}
                  type="checkbox"
                />
                <span style={{ fontSize: 12 }}>
                  I would like to recieve booking updates on Whatsapp{" "}
                </span>
              </div>
              <h1 className="header-font center mb-20 mt-10 color-healthship">What Our Customers Say</h1>
              <TestimonialCardCarousel cards={TestimonialsData}  />
              <div className="checkout-btn">
                {!openBillSection && (
                  <div
                    className="pointer"
                    onClick={() => setOpenBillSection(!openBillSection)}
                  >
                    <span className="color-healthship text-font">
                      Payment Details
                    </span>{" "}
                    <AngleUpIcon />
                  </div>
                )}
                {openBillSection && (
                  <div
                    className="pointer"
                    onClick={() => setOpenBillSection(!openBillSection)}
                  >
                    <span className="color-healthship text-font">
                      Payment Details
                    </span>{" "}
                    <AngleDownIcon />
                  </div>
                )}
                {openBillSection && (
                  <div>
                    <div className="flex mb-10 text-font">
                      <span className="ml-10">{billData?.name}</span>
                      <span className="ml-auto mr-10">Rs {billData.price}</span>
                    </div>
                    <div className="flex mb-10 text-font">
                      <span className="ml-10">ItemTotal</span>
                      <span className="ml-auto mr-10">
                        Rs {billData.price}
                      </span>
                    </div>
                    <div className="flex mb-10 text-font">
                      <span className="ml-10">GST</span>
                      <span className="ml-auto mr-10">Rs {billData.gst}</span>
                    </div>
                    <div className="flex mb-10 text-font">
                      <span className="ml-10">To Pay</span>
                      <span className="ml-auto mr-10">
                        Rs {billData.total_price}
                      </span>
                    </div>
                  </div>
                )}
                <div className="flex">
                  <span className="to-pay">
                    <div>To Pay</div>
                    <div>Rs {billData.total_price}</div>
                  </span>
                  <span className="mr-20 ml-auto">
                    <StyledButton
                      className="button large mt-10 mb-10"
                      type="submit"
                    >
                      <span>Book Session Now</span>
                    </StyledButton>
                  </span>
                </div>
              </div>
            </form>
            <OtpModal
              mobile={mobile}
              isOtp={isOtpSent}
              handleCheckoutState={handleCheckoutState}
              handleIsOtp={handleIsOtp}
            />
          </div>
        </div>
      </div>
      {/* <WhatsappBtn /> */}
    </div>
  );
}
