import React, { useState } from "react";

export interface ICheckout {
  // name: string;
  // email: string;
  mobile: number;
  // languagesYouKnow: any;
  sessionData: any;
  // firstMessage: string;
  whatsappSubscribed: boolean
}

export interface ICalendly {
  eventDate: any;
}

export type CheckoutContextType = {
  checkoutData: ICheckout[];
  calendlyData: ICalendly;
  saveCheckoutData: (checkoutData: ICheckout) => void;
  saveCalendlyData: (calendlyData: ICalendly) => void;
};

export const CheckoutContext = React.createContext<CheckoutContextType | null>(
  null
);

const CheckoutProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [checkoutData, setCheckoutData] = React.useState<ICheckout[]>([]);
  const [calendlyData, setCalendltData] = useState({
    eventDate: ""
  });

  const saveCheckoutData = (checkoutForm: ICheckout) => {
    setCheckoutData([checkoutForm]);
  };

  const saveCalendlyData = (calendlyData: ICalendly) => {
    setCalendltData({
      eventDate: calendlyData.eventDate
    })
  }

  return (
    <CheckoutContext.Provider value={{ checkoutData, saveCheckoutData, calendlyData, saveCalendlyData }}>
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutProvider;
