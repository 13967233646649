import React, { useEffect, useState } from "react";
import classnames from "classnames";

import "./styles.css";

export const ButtonType = {
    BUTTON: 'button',
    RESET: 'reset',
    SUBMIT: 'submit',
  }
  
  export const ButtonTheme = {
    DEFAULT: 'default',
    ROUNDED: 'rounded',
  }
  
  export const ButtonSize = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
  }

type Props = {
    type?: "button" | "reset" | "submit" | undefined;
    theme: string;
    size: string;
    onClick: () => void,
    children: React.ReactNode,
    className: string,
    disabled: boolean
}

const StyledButton = (props: Props) => {
    const { type, onClick, children, theme, size, className, disabled } = props;

    return (<button type={type} className={className} disabled={disabled} onClick={onClick}>
        {children}
    </button>)

} 

StyledButton.defaultProps = {
    type: ButtonType.BUTTON,
    theme: ButtonTheme.DEFAULT,
    size: ButtonSize.MEDIUM,
    onClick: () => {},
    className: '',
    disabled: false,
  }
  

export default StyledButton;