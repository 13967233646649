export const AssessmentData: any = {
  "overall-well-being": {
    typeform: "rrPrqeWO",
    jotLink: "https://form.jotform.com/232321174771451",
    seoTitle: "Mental Well Being Assessment - Healthship",
    seoDesc:
      "Take our Mental Well-Being assessment based on the Ryff Scales of Psychological Well-Being to understand your mental well-being and consult our therapists for more help.",
  },
  depression: {
    typeform: "TFgGaR9u",
    jotLink: "https://form.jotform.com/232343482078457",
    seoTitle: "Depression Asessment - Healthship",
    seoDesc:
      "Take this depression test which is based on the PHQ-9 standard to understand your symptoms better. And get help from our therapists for your issues.",
  },
  anxiety: {
    typeform: "RiPO0f8w",
    jotLink: "https://form.jotform.com/232343786328462",
    seoTitle: "Anxiety Assessment - Healthship - Your Mental Health Partner",
    seoDesc:
      "Take this assessment based on the GAD-7 standard to understand your symptoms of anxiety and get the support you need from our therapists.",
  },
  "career-stress": {
    typeform: "z2lhCJJO",
    jotLink: "https://form.jotform.com/232345267167459",
    seoTitle: "Career Fulfillment Assessment - Healthship",
    seoDesc:
      "Take our career stress assessment based on the JSI standard and understand the places where you need help with our specialized therapists.",
  },
  adhd: {
    typeform: "pQp79O1R",
    jotLink: "https://form.jotform.com/232345267167459",
    seoTitle: "ADHD Assessment - Healthship - Your Mental Health Partner",
    seoDesc:
      "Take this ADHD assessment based on ASRS and understand our symptoms better. Talk to our therapists to get easy and confidential care for your conditions.",
  },
};

export const TestimonialsData: any = [
  {
    name: "Akarsh Dwiwedi",
    testimonial:
      "I never knew a therapist's presence could actually make me so warm from inside. Thank you, Healthship.",
    // imgUrl: "1.webp",
  },
  {
    name: "Rashida Lohawala",
    testimonial:
      "Yesterday I was crying a lot because I felt alone. Then I took a therapy session with Healthship and felt much better.",
    // imgUrl: "2.webp",
  },
  {
    name: "Somya Maheshwari",
    testimonial:
      "I cannot imagine not taking therapy now; it has changed my life in so many ways, and this was only possible because of Healthship.",
    // imgUrl: "3.webp",
  },
];

export const DoctorData: any = {
  "2": {
    doctorType: "Counselling Therapist",
    sessionTime: "20",
    language: "Hindi, English",
  },
  "3": {
    doctorType: "Counselling Therapist",
    sessionTime: "20",
    language: "Hindi, English",
    testimonials: [
      {
        name: "Mandira",
        testimonial:
          "I've had great help with the online therapy session being organised by Healthship! I recommend the same who are seeking help or want to seek help in future!",
      },
      {
        name: "Priyanshu",
        testimonial:
          "It's been a great time getting help from these guys. The entire team is very helpful. Please feel free to contact them to seek help. Don't ignore your mental health like I did. Ask for help.",
      },
      {
        name: "Akarsh Dwiwedi",
        testimonial:
          "I never knew a therapist's presence could actually make me so warm from inside. Thank you, Healthship.",
        // imgUrl: "1.webp",
      },
    ],
    specialisation: [
      {
        name: "CBT",
        imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_1.png"
      },
      {
        name: "DBT",
        imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_2.png"
      },
      {
        name: "Trauma-informed, Couple therapy",
        imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_3.png"
      },
      {
        name: "EFT",
        imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_1.png"
      },
      {
        name: "ACT",
        imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_2.png"
      }
      ]
  },
  "4": {
    doctorType: "Counselling Therapist",
    sessionTime: "20",
    language: "Hindi, English",
  },
  "5": {
    doctorType: "RCI Registered Therapist",
    sessionTime: "20",
    language: "Hindi, English",
  },
  "6": {
    doctorType: "RCI Registered Therapist",
    sessionTime: "20",
    language: "Hindi, English",
  },
  "7": {
    doctorType: "RCI Registered Therapist",
    sessionTime: "20",
    language: "Hindi, English, Malayalam",
  },
  "8": {
    doctorType: "Counselling Therapist",
    sessionTime: "20",
    language: "Hindi, English, Assamese",
  },
};

export const FAQData = [
  {
    question: "What is Healthship?",
    answer:
      "'Healthship' is an online platform for emotional, physical and psychological well-being. At Healthship, we offer comprehensive online counselling and therapy, provide valuable information on psychological wellness, and work with organisations to meet similar goals. We provide one-on-one video consultations with a team of expert counsellors, Clinical and Counselling Psychologists, therapists, and psychiatrists.",
  },
  {
    question: "How can Healthship help me with my Mental Health?",
    answer:
      "Healthship is where you can express your emotions freely in front of Mental Health experts on a Video or Audio call. You can seek guidance, Share experiences and find comfort in managing your Mental Health.",
  },
  {
    question: "Who is a therapist?",
    answer:
      "A therapist is a licensed Mental Health professional who can help you provide a non-judgemental space where you can seek guidance, discuss your problems and help you confront your emotions to help you learn and thrive in your life.",
  },
  {
    question: "What are the different types of therapists?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    question: "Is Online therapy as effective as in-person therapy?",
    answer:
      "A lot of research has shown that Online Therapy is as effective as in-person therapy. Online therapy provides an option of time flexibility as well as it can provide you with the option of choosing the best therapist even if they are not in the same city as you while maintaining privacy and confidentiality. If you are someone who lives in a remote area, online therapy would be the most accessible option for you.",
  },
  {
    question: "What happens in the first therapy session?",
    answer: "The first session looks very different and daunting as compared to the sessions that follow because you are meeting your therapist for the first time. The first online therapy session will be centred around rapport formation with your therapist with the motive of getting to know you better. You’d be asked simple questions that will help them get more insights about your life, daily schedule, thoughts and emotions, behaviour patterns, likes and dislikes and the area of life you wish to work on."
  },
  {
    question: "What sort of concerns are handled by Healthship?",
    answer: "Experts at Healthship are professionally trained to deal with anxiety, personality problems, depression, problems related to excessive use of drugs, alcohol and smoking, emotional control issues, sexual concerns, and eating disorders. We also manage relationship concerns, stress-related concerns (job, studies), adjustment difficulties, problems related to lifestyles, family conflict, parental guidance, anger management, time management and communication skills."
  },
  {
    question: "How long does a therapy session last?",
    answer: "Therapy sessions at Healthship can be either 20 minutes long or 50 minutes long. You can select a slot based on your needs and preferences. But in some cases, depending on the concern and the frequency of sessions, the therapist might opt for a longer session as well. This, however, is entirely at the discretion of the therapist and hence we advise you to discuss the same with him/her. It is good practice to stick to session timings. However, in exceptional circumstances, if your session is running over time because a critical concern is being explored, you can request your therapist for an extension which they will accommodate solely based on their discretion and availability. The fee for the extension would be adjusted later."
  },
  {
    question: "Will my information be kept confidential?",
    answer: "Yes, all your information is kept secure and confidential."
  },
  {
    question: "How do you pick the right therapist?",
    answer: "You can check what issues a therapist deals with, your specific choice of gender(If any) whom you would feel comfortable speaking with, age and their years of experience. If you're unsure of who to talk to, you can reach out to us on our WhatsApp. Our team will assist you in finding the best experts to help you heal and grow."
  },
  {
    question: "Can I talk to someone if I have any queries?",
    answer: "Yes, you can speak to our team on WhatsApp regarding any queries that you have."
  },
  {
    question: "How many sessions will I have to attend?",
    answer: "Every patient has to attend a different number of sessions so there is no specific number of sessions. But we recommend attending at least 3-5 sessions, that is when most people start seeing noticeable results and changes in their behaviour."
  },
  {
    question: "How can I prepare for my first session?",
    answer: "You have already taken a step toward better mental health by choosing to go to therapy. Acknowledge it and be proud of the effort you have put in! Remember to set goals, be open and honest, and have realistic expectations. Schedule your session at a convenient time and keep an open mind. You can also note down a few things you’d like to discuss with your therapist beforehand."
  },
  {
    question: "Why should I talk to a therapist?",
    answer: ""
  },
  {
    question: "How to book a session?",
    answer: ""
  },
  {
    question: "Am I eligible for a refund if I cancel a session?",
    answer: "If you cancel your appointment you will not be eligible for a refund."
  },  
  {
    question: "Can I reschedule a confirmed session?",
    answer: `All sessions can be rescheduled. The rescheduling charges are based on the time of the rescheduling in relation to the session’s start time:
    If you reschedule your appointment at least 24 hours or more before your confirmed session time; there will be no additional charge for rescheduling with the same expert. Sessions have to be rebooked to another date within a year of the initial booking.
    If you reschedule your appointment less than 24 hours before your confirmed session time; we cannot refund the fee as the therapist would have blocked this time and will be unable to see another client at such short notice. We would be delighted to make a fresh booking for you after cancelling your current session. You will not be eligible for any refund on the cancelled session. You will incur the full session fee for the next session.
    `
  },
];

export const FAQData1 = [
  {
    question: "What is Healthship?",
    answer:
      "'Healthship' is an online platform for emotional, physical and psychological well-being. At Healthship, we offer comprehensive online counselling and therapy, provide valuable information on psychological wellness, and work with organisations to meet similar goals. We provide one-on-one video consultations with a team of expert counsellors, Clinical and Counselling Psychologists, therapists, and psychiatrists.",
  },
  {
    question: "How can Healthship help me with my Mental Health?",
    answer:
      "Healthship is where you can express your emotions freely in front of Mental Health experts on a Video or Audio call. You can seek guidance, Share experiences and find comfort in managing your Mental Health.",
  },
  {
    question: "Who is a therapist?",
    answer:
      "A therapist is a licensed Mental Health professional who can help you provide a non-judgemental space where you can seek guidance, discuss your problems and help you confront your emotions to help you learn and thrive in your life.",
  },
  {
    question: "What are the different types of therapists?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat",
  },
  {
    question: "Is Online therapy as effective as in-person therapy?",
    answer:
      "A lot of research has shown that Online Therapy is as effective as in-person therapy. Online therapy provides an option of time flexibility as well as it can provide you with the option of choosing the best therapist even if they are not in the same city as you while maintaining privacy and confidentiality. If you are someone who lives in a remote area, online therapy would be the most accessible option for you.",
  },
  {
    question: "What happens in the first therapy session?",
    answer: "The first session looks very different and daunting as compared to the sessions that follow because you are meeting your therapist for the first time. The first online therapy session will be centred around rapport formation with your therapist with the motive of getting to know you better. You’d be asked simple questions that will help them get more insights about your life, daily schedule, thoughts and emotions, behaviour patterns, likes and dislikes and the area of life you wish to work on."
  },
  {
    question: "What sort of concerns are handled by Healthship?",
    answer: "Experts at Healthship are professionally trained to deal with anxiety, personality problems, depression, problems related to excessive use of drugs, alcohol and smoking, emotional control issues, sexual concerns, and eating disorders. We also manage relationship concerns, stress-related concerns (job, studies), adjustment difficulties, problems related to lifestyles, family conflict, parental guidance, anger management, time management and communication skills."
  },
  {
    question: "How long does a therapy session last?",
    answer: "Therapy sessions at Healthship can be either 20 minutes long or 50 minutes long. You can select a slot based on your needs and preferences. But in some cases, depending on the concern and the frequency of sessions, the therapist might opt for a longer session as well. This, however, is entirely at the discretion of the therapist and hence we advise you to discuss the same with him/her. It is good practice to stick to session timings. However, in exceptional circumstances, if your session is running over time because a critical concern is being explored, you can request your therapist for an extension which they will accommodate solely based on their discretion and availability. The fee for the extension would be adjusted later."
  },
  {
    question: "Will my information be kept confidential?",
    answer: "Yes, all your information is kept secure and confidential."
  },
  {
    question: "How do you pick the right therapist?",
    answer: "You can check what issues a therapist deals with, your specific choice of gender(If any) whom you would feel comfortable speaking with, age and their years of experience. If you're unsure of who to talk to, you can reach out to us on our WhatsApp. Our team will assist you in finding the best experts to help you heal and grow."
  },
  {
    question: "Can I talk to someone if I have any queries?",
    answer: "Yes, you can speak to our team on WhatsApp regarding any queries that you have."
  },
  {
    question: "How many sessions will I have to attend?",
    answer: "Every patient has to attend a different number of sessions so there is no specific number of sessions. But we recommend attending at least 3-5 sessions, that is when most people start seeing noticeable results and changes in their behaviour."
  },
  {
    question: "How can I prepare for my first session?",
    answer: "You have already taken a step toward better mental health by choosing to go to therapy. Acknowledge it and be proud of the effort you have put in! Remember to set goals, be open and honest, and have realistic expectations. Schedule your session at a convenient time and keep an open mind. You can also note down a few things you’d like to discuss with your therapist beforehand."
  },
  {
    question: "Why should I talk to a therapist?",
    answer: ""
  },
  {
    question: "How to book a session?",
    answer: ""
  },
  {
    question: "Am I eligible for a refund if I cancel a session?",
    answer: "If you cancel your appointment you will not be eligible for a refund."
  },  
  {
    question: "Can I reschedule a confirmed session?",
    answer: `All sessions can be rescheduled. The rescheduling charges are based on the time of the rescheduling in relation to the session’s start time:
    If you reschedule your appointment at least 24 hours or more before your confirmed session time; there will be no additional charge for rescheduling with the same expert. Sessions have to be rebooked to another date within a year of the initial booking.
    If you reschedule your appointment less than 24 hours before your confirmed session time; we cannot refund the fee as the therapist would have blocked this time and will be unable to see another client at such short notice. We would be delighted to make a fresh booking for you after cancelling your current session. You will not be eligible for any refund on the cancelled session. You will incur the full session fee for the next session.
    `
  },
];

export const TherapyData = [
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/depression.png",
    title: "Clinical Depression",
  },
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/anxiety.png",
    title: "Anxiety Disorders",
  },
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/depression.png",
    title: "OCD",
  },
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/anxiety.png",
    title: "Sexual Disorders",
  },
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/depression.png",
    title: "Eating and Sleep Disorders",
  },  
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/grief.png",
    title: "Trauma",
  },
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/bmi.png",
    title: "Relationship and Marital Problems",
  },
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/grief.png",
    title: "Addictions",
  },
  {
    imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/bmi.png",
    title: "Workplace Issues",
  }
];

export const calendlyData = {
  "1": {
    url: "https://calendly.com/jain-anuragj/1",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk5MzM0OTc1LCJqdGkiOiI0NjVhYzgwNi1kZmExLTRhMDYtODAyYi05M2NiMjllZTcyYzkiLCJ1c2VyX3V1aWQiOiIyZTY2ZjgyYS00N2E5LTRjYzktOGYyZi02MTdmYTZmYzNmYWQifQ.JsfLiOozVK5cm4DIJYL1x6EeZijLxjce4bkueHTK7tOUdM7bjKtwuBCh-CTBAwnei-cKiUWCSEbf5Xj4LM7enQ",
  },
  "2": {
    url: "https://calendly.com/shubhranshuldwivedi/2",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzA0MzkxOTQ1LCJqdGkiOiJiZjY2MDJkMy1hYWMzLTRlZDUtOWQwMi1jNzRkZTI5OWFkY2IiLCJ1c2VyX3V1aWQiOiI3ZjkxMGMwYi1kMzlkLTQ1MzQtYmRmMy1mMDJlZTBhMDhlMjkifQ.mHSwNs3zD4DDA3qSMhMuBMi8EP9ZK3hrME-RoF9O7wt6c_AVIgEJaE3Sia3fXHbec7Ac_XP2E-EOgya17WJDtQ",
  },
  // "2": {
  //   url: "https://calendly.com/ajanurag09/30min",
  //   url50: "https://calendly.com/ajanurag09/50-min-meeting",
  //   token:
  //     "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE5Mjk3MDk3LCJqdGkiOiIxNGEwZTJlZi01ODdkLTRlN2EtYTQzMC01YmFmODAyMWJkMDQiLCJ1c2VyX3V1aWQiOiI3NGI1MzNjMS1hZmRkLTQ0OGItOGNhZS04YTYwMGU4MGM2NDgifQ._OW2qgO31uZmnSnxbjNkYEvufIE1FT8jpqsh0-6I0dTXXketLu6pHdx10nnP6hZ8PTMaObiZu95REoBKrSRQRQ",
  // },
  "3": {
    url: "https://calendly.com/ajanurag09/30min",
    url50: "https://calendly.com/ajanurag09/50-min-meeting",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE5Mjk3MDk3LCJqdGkiOiIxNGEwZTJlZi01ODdkLTRlN2EtYTQzMC01YmFmODAyMWJkMDQiLCJ1c2VyX3V1aWQiOiI3NGI1MzNjMS1hZmRkLTQ0OGItOGNhZS04YTYwMGU4MGM2NDgifQ._OW2qgO31uZmnSnxbjNkYEvufIE1FT8jpqsh0-6I0dTXXketLu6pHdx10nnP6hZ8PTMaObiZu95REoBKrSRQRQ",
  },
  // "3": {
  //   url: "https://calendly.com/nanditakalra-xoy/2",
  //   url50: "https://calendly.com/nanditakalra-xoy/2",
  //   token:
  //     "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzA1MDY1NzA5LCJqdGkiOiJiYTQ0ZjE1YS04NWYyLTQxZWMtODM5Ny04NmZjZmYzNzNjMzQiLCJ1c2VyX3V1aWQiOiIyZWJlYjI0MC01OTk3LTQyOWYtYTI3Zi1kZTNjYzJkNjhkMGYifQ.3zHl9R37bBm8va-Egw78IxgBFEfCng363XpD7kpmWNkgTxhJyLCqKjRiiK6JaP6jtMwAXiPncb_bbeL7Ep6YWg",
  // },
  "4": {
    url: "https://calendly.com/avinashmandal/4",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzA0MzkxNTgyLCJqdGkiOiJjMzQyODQyYS1jZTcxLTQ3NzEtYTY2OC02OWMzYTA5ZmI5NWMiLCJ1c2VyX3V1aWQiOiJlN2JkYmY4My0zMWQ2LTQxYzctOTk2OS0xMzU2Yjg3YmUzNTYifQ._sUvCaHNZwdAO414dvzaW5eiE48qLZsk-iTidhn3N-FtixrsUgS9e1w-ZwIWVSlDSyNaCowXlP9232aTlXH4bw",
  },
  "5": {
    url: "https://calendly.com/drishtemadan/2",
    url50: "https://calendly.com/drishtemadan/2",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzA0MzkwNzQ1LCJqdGkiOiI2Mjc2YWI0Zi1iYzM3LTRjYzMtYjBjZC0wYzgwN2Y1YWFiMTkiLCJ1c2VyX3V1aWQiOiIwNzk0NDBjNy0wYTI5LTQwZDQtODg4Yy03YTZlNjNlNjk4YzgifQ.3DMeXxls7WGQfI6y7qATAZ-qmIuE-JzDCVAoyFQTHuHeh56ELk_QLisJRQlpKbIOnFrpp9sjxeD4mK8fLwzTRQ",
  },
  "6": {
    url: "https://calendly.com/alka-sankla/30min",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE1OTM5Nzg0LCJqdGkiOiJiMWE1Y2MyMi1hMmRiLTRjMzItOTc4MS04YzYzNzc0NjQyZjMiLCJ1c2VyX3V1aWQiOiJkMDVjZDMzZi05NTEwLTQ1NjAtOGRmYy1jMWIzNDU0YzMxNWQifQ.mLexTwyLLbFZxHa2eIOqWkLsvJ6PEOaOJINrKOJa0iteyjuVs9BP7FUF27Rle3bK622PWLwtzqx_Ob_GWE1WAw",
  },
  "7": {
    url: "",
    url50: "https://calendly.com/prateek-yadav-healthship/30min",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE3NTg0ODU1LCJqdGkiOiIyMDViNzQxNi0yZGNiLTRlZDAtOTQ3ZS0wM2UyZjk4MTFlNTkiLCJ1c2VyX3V1aWQiOiIwYTQxMjA0OC04YmI2LTQ5MWEtOWQ0Ny0wZWU2ZGM2MWViMTQifQ.3c1W7zFrVLRXnHVPFhC9JMttx5669B81r9wtLrgZtYrvUUyIQwxqhKOn6GCbzWu5frrO8aQ490IRHKg3nYXZLg",
  },
  "8": {
    url: "https://calendly.com/khushi-khakholia-healthship/30min",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE4OTcxNTUwLCJqdGkiOiIzN2ZiYTAyNS1mMzhiLTRiNTctODYyMi1mNGZkNDZlZTg3ZTkiLCJ1c2VyX3V1aWQiOiIwOTk5MjZiMC1iNmM4LTRkNTctODYzYS02NmUzNjk4NDUyYWQifQ.TEOgtqQzremVHplGHm7ebzRECKdHEuWhEDFrWP1OBGB7SkXpS8QtxgxzTFcZUqJBMFRfMmqygigAQ8T_AOXYSQ",
  },
};

export const SpecialisationData = [
{
  name: "CBT",
  imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_1.png"
},
{
  name: "DBT",
  imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_2.png"
},
{
  name: "Trauma-informed, Couple therapy",
  imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_3.png"
},
{
  name: "ACT",
  imgUrl: "https://healthship-production.s3.ap-south-1.amazonaws.com/therapyimages/sp_1.png"
}
]
