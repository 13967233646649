import Modal from "react-modal";
import React, { useContext, useEffect, useState } from "react";
import { sendOtp } from "../../services/user-service";
import { AuthContext, UserContextType } from "../../context/authContext";
import { useForm, SubmitHandler } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import LoaderModal from "../loader";
import StyledButton from "../ui/StyledButton/StyledButton";
import { CloseModalIcon } from "../icons";
import { toastFn } from "../../utils/helpers";

export interface OtpModel {
  mobile: string;
  isOtp: boolean;
  handleCheckoutState: () => void;
  handleIsOtp: () => void;
}

type FormValues = {
  otp: any;
};

const OtpModal = ({ mobile, isOtp, handleCheckoutState, handleIsOtp }: OtpModel) => {
  const {
    register: otpRegister,
    handleSubmit: submitOtp,
    formState: { errors },
  } = useForm<FormValues>({ criteriaMode: "all" });
  const customStyles = {
    content: {
      height: "250px",
      top: "35%",
    },
  };

  const [openModal, setOpenModal] = useState(false);
  const { isAuthenticated, login, userData } = useContext(
    AuthContext
  ) as UserContextType;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setOpenModal(isOtp);
  }, [isOtp]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    if (mobile != null) {
      setIsLoading(true);
      login(mobile, data.otp).then((res) => {
        if(res) {
          setIsLoading(false);
          handleCheckoutState();
        }
      }).catch((error) => {
        toastFn("Please Enter the Valid OTP");
      });
    }
  };

    const resendOTP = () => {
      sendOtp(mobile)
        .then((res) => {
          toastFn("OTP sent successfully");
        })
        .catch((error) => {
          toastFn("Please Request for the OTP again");
        });
    };

    const closeModal = () => {
      setOpenModal(false);
      handleIsOtp();
    };

  return (
    <Modal isOpen={openModal} onRequestClose={closeModal} style={customStyles}>
      <span style={{ position: "absolute", right: 20, top: 10 }} onClick={closeModal}>
      <CloseModalIcon />
      </span>
      <form className="form" onSubmit={submitOtp(onSubmit)}>
        <label className="otp-input center header-font color-healthship">
          Confirm OTP
        </label>
        <input
          className="otp"
          {...otpRegister("otp", {
            required: "Enter an OTP",
            minLength: {
              value: 6,
              message: "OTP should be of 6 digits",
            },
            maxLength: {
              value: 6,
              message: "OTP should be of 6 digits",
            },
          })}
        />
        {/* {errors.otp && <div>{errors.otp.message}</div>} */}
        <ErrorMessage
          errors={errors}
          name="otp"
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type, message]) => (
              <p className="error-message" key={type}>
                {message}
              </p>
            ))
          }
        />
        <div style={{ textAlign: "center" }}>
        <StyledButton className="button medium" type="submit">
          Verify OTP
        </StyledButton>
        </div>
        <div className="center pointer mt-10 text-font" onClick={resendOTP}>
          Resend OTP
        </div>
        <LoaderModal isLoading={isLoading} />
      </form>
    </Modal>
  );
};

export default OtpModal;
