const Education = () => {
  return (
    <section id="education" className="education-section mt-20">
      <h2 className="small-header-font mb-10">Education</h2>
      <div>
        <ul>
          <li>MA Psychology Ambedkar University, Delhi</li>
          <li>Advanced Diploma in Counselling Psychology, Jamia Millia Islamia, Delhi</li>
        </ul>
      </div>
    </section>
  );
};

export default Education;
