import React, { useContext, useEffect, useState } from "react";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import axios from "axios";
import {
  CheckoutContext,
  CheckoutContextType,
} from "../../context/checkoutContext";

interface MyData {
  [key: string | number]: { url: string; url50: string; token: string };
}

interface CalendlyProps {
  sessionTime: string;
  handleCalendly: () => void;
}

const calendlyObj: MyData = {
  "1": {
    url: "https://calendly.com/jain-anuragj/1",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk5MzM0OTc1LCJqdGkiOiI0NjVhYzgwNi1kZmExLTRhMDYtODAyYi05M2NiMjllZTcyYzkiLCJ1c2VyX3V1aWQiOiIyZTY2ZjgyYS00N2E5LTRjYzktOGYyZi02MTdmYTZmYzNmYWQifQ.JsfLiOozVK5cm4DIJYL1x6EeZijLxjce4bkueHTK7tOUdM7bjKtwuBCh-CTBAwnei-cKiUWCSEbf5Xj4LM7enQ",
  },
  "2": {
    url: "https://calendly.com/shubhranshuldwivedi/2",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzA0MzkxOTQ1LCJqdGkiOiJiZjY2MDJkMy1hYWMzLTRlZDUtOWQwMi1jNzRkZTI5OWFkY2IiLCJ1c2VyX3V1aWQiOiI3ZjkxMGMwYi1kMzlkLTQ1MzQtYmRmMy1mMDJlZTBhMDhlMjkifQ.mHSwNs3zD4DDA3qSMhMuBMi8EP9ZK3hrME-RoF9O7wt6c_AVIgEJaE3Sia3fXHbec7Ac_XP2E-EOgya17WJDtQ",
  },
  "3": {
    url: "https://calendly.com/nanditakalra-xoy/2",
    url50: "https://calendly.com/nanditakalra-xoy/therapy",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzA1MDY1NzA5LCJqdGkiOiJiYTQ0ZjE1YS04NWYyLTQxZWMtODM5Ny04NmZjZmYzNzNjMzQiLCJ1c2VyX3V1aWQiOiIyZWJlYjI0MC01OTk3LTQyOWYtYTI3Zi1kZTNjYzJkNjhkMGYifQ.3zHl9R37bBm8va-Egw78IxgBFEfCng363XpD7kpmWNkgTxhJyLCqKjRiiK6JaP6jtMwAXiPncb_bbeL7Ep6YWg",
  },
  "4": {
    url: "https://calendly.com/avinashmandal/4",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzA0MzkxNTgyLCJqdGkiOiJjMzQyODQyYS1jZTcxLTQ3NzEtYTY2OC02OWMzYTA5ZmI5NWMiLCJ1c2VyX3V1aWQiOiJlN2JkYmY4My0zMWQ2LTQxYzctOTk2OS0xMzU2Yjg3YmUzNTYifQ._sUvCaHNZwdAO414dvzaW5eiE48qLZsk-iTidhn3N-FtixrsUgS9e1w-ZwIWVSlDSyNaCowXlP9232aTlXH4bw",
  },
  "5": {
    url: "https://calendly.com/drishtemadan/2",
    url50: "https://calendly.com/drishtemadan/2",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzA0MzkwNzQ1LCJqdGkiOiI2Mjc2YWI0Zi1iYzM3LTRjYzMtYjBjZC0wYzgwN2Y1YWFiMTkiLCJ1c2VyX3V1aWQiOiIwNzk0NDBjNy0wYTI5LTQwZDQtODg4Yy03YTZlNjNlNjk4YzgifQ.3DMeXxls7WGQfI6y7qATAZ-qmIuE-JzDCVAoyFQTHuHeh56ELk_QLisJRQlpKbIOnFrpp9sjxeD4mK8fLwzTRQ",
  },
  "6": {
    url: "https://calendly.com/alka-sankla/30min",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE1OTM5Nzg0LCJqdGkiOiJiMWE1Y2MyMi1hMmRiLTRjMzItOTc4MS04YzYzNzc0NjQyZjMiLCJ1c2VyX3V1aWQiOiJkMDVjZDMzZi05NTEwLTQ1NjAtOGRmYy1jMWIzNDU0YzMxNWQifQ.mLexTwyLLbFZxHa2eIOqWkLsvJ6PEOaOJINrKOJa0iteyjuVs9BP7FUF27Rle3bK622PWLwtzqx_Ob_GWE1WAw",
  },
  "7": {
    url: "https://calendly.com/sreevidhya-kottarapat-healthship/30min",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE5ODQxMDEyLCJqdGkiOiIzZDBlMmFjNC1hZGViLTRkMGQtYWFlNy1iZGU3NzVhNjBmOTMiLCJ1c2VyX3V1aWQiOiJiNzJiNzJiMS1iZDJhLTQ4MTctYWFiZC1hZmZmZjk0NjEzZTUifQ.25VZTgoTOx1OqBJPPXFYW4IKOatSCw0xDi4hTUFudbL0Sj5l1TUEqOFE6ndJJalu5iCdnZgDlaKH8gseijZVNA",
  },
  "8": {
    url: "https://calendly.com/khushi-khakholia-healthship/30min",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE4OTcxNTUwLCJqdGkiOiIzN2ZiYTAyNS1mMzhiLTRiNTctODYyMi1mNGZkNDZlZTg3ZTkiLCJ1c2VyX3V1aWQiOiIwOTk5MjZiMC1iNmM4LTRkNTctODYzYS02NmUzNjk4NDUyYWQifQ.TEOgtqQzremVHplGHm7ebzRECKdHEuWhEDFrWP1OBGB7SkXpS8QtxgxzTFcZUqJBMFRfMmqygigAQ8T_AOXYSQ",
  },
};

const Calendly = ({sessionTime, handleCalendly }: CalendlyProps) => {
  const [calendlyData, setCalendlyData] = useState({
    url: "https://calendly.com/jain-anuragj/1",
    url50: "",
    token:
      "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjk5MzM0OTc1LCJqdGkiOiI0NjVhYzgwNi1kZmExLTRhMDYtODAyYi05M2NiMjllZTcyYzkiLCJ1c2VyX3V1aWQiOiIyZTY2ZjgyYS00N2E5LTRjYzktOGYyZi02MTdmYTZmYzNmYWQifQ.JsfLiOozVK5cm4DIJYL1x6EeZijLxjce4bkueHTK7tOUdM7bjKtwuBCh-CTBAwnei-cKiUWCSEbf5Xj4LM7enQ",
  });
  const [isOpen, setIsOpen] = useState(true);
  const [calendlyUrl, setCalendlyUrl] = useState("");

  const { saveCalendlyData } = useContext(
    CheckoutContext
  ) as CheckoutContextType;

  useEffect(() => {
    const id = localStorage.getItem("DoctorId");
    if (id) {
      setCalendlyData(calendlyObj[id]);
      const sesTime = localStorage.getItem("SessionTime") || "";
      console.log(sesTime);
      if(sesTime == "50") {
        console.log("we were here for 50 mins");
        setCalendlyUrl(calendlyObj[id].url50);
      } else {
        setCalendlyUrl(calendlyObj[id].url);
        console.log("we were here for 20 mins");
      }  
    }
  }, []);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      const event = e.data.payload.event.uri;
      const arr = event.split("/");
      const eventId = arr[arr.length - 1];
      setIsOpen(false);
      axios
        .get(`https://api.calendly.com/scheduled_events/${eventId}`, {
          headers: {
            Authorization: "Bearer " + calendlyData.token,
          },
        })
        .then((res) => {
          // Set Event date and time, also redirect to the next page
          saveCalendlyData({
            eventDate: res.data.resource.start_time,
          });
          localStorage.setItem("AppointmentDate", res.data.resource.start_time);
          handleCalendly();
        })
        .catch((error) => {
          console.log("Error, please try again");
        });
    },
  });

  return (
    <div>
      {isOpen && (
        <div>
          <InlineWidget url={calendlyUrl} />
        </div>
      )}
    </div>
  );
};

export default Calendly;
