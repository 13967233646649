import { http } from "../utils/http-common";

export interface SessionData {
  sessionId: number;
  bookingDate: string;
  bookingTime: string;
  message: any;
  sessionPrice: number;
  callbackUrl: string;
  whatsappSubscription: any;
}

const processToPayment = (sessionData: SessionData) => {
  return http.post(
    "/booking/pay",
    {
      session_id: sessionData.sessionId,
      booking_date: sessionData.bookingDate,
      booking_time: sessionData.bookingTime,
      message_to_therapist: sessionData.message,
      session_price: sessionData.sessionPrice,
      callback_url: sessionData.callbackUrl,
      whatsapp_subscription: sessionData.whatsappSubscription
    },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
};

const getLanguages = () => {
  return http.get("/languages")
}

const applyPromotion = (coupon: string) => {
  return http.post("/coupon/validate", {
    code: coupon
  })
}

const orderStatus = (paymentId: string) => {
  return http.get("/payment/status?id=" + paymentId);
}

export { processToPayment, getLanguages, applyPromotion, orderStatus };
