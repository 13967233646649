import { TherapyData } from "../../data/PageData";
import MultiCarousel from "../multi-carousel/MultiCarousel";

interface TherapyContainerProps {
  imgUrl: string,
  title: string
}

const TherapyCarousel = () => {
  return (
    <section className="therapy_type mt-20">
      <h2 className="mb-10">I offer Therapy for</h2>
      <div>
        <MultiCarousel carouselVal="isTherapy" carouselData={TherapyData} />
      </div>
    </section>
  );
};

export const TherapyContainer = ({imgUrl, title}: TherapyContainerProps) => {
  return (
    <div className="center">
    <div>
      <img width={100} height={100} src={imgUrl} />
    </div>
    <p>{title}</p>
  </div>

  )
}

export default TherapyCarousel;
