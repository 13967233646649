import React from 'react';
import './SectionCard.css';

interface CardProps {
  title?: string;
  content: string;
  step?: string; // Optional image prop
}

const SectionCard: React.FC<CardProps> = ({ title, content, step }) => {
  return (
    <div className="card">
      <h1 className='center color-healthship'>{step}</h1>
      <div className="card-body">
        {title && <h3 className='small-header-font'>{title}</h3>}
        <p className='mt-10 text-font'>{content}</p>
      </div>
    </div>
  );
};

export default SectionCard;