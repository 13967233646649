import { useEffect, useState, useContext } from "react";
import "./MobileNav.css";
import Modal from "react-modal";
import { AngleDownIcon, AngleUpIcon, CloseModalIcon, UserIcon } from "../icons";
import { useNavigate } from "react-router-dom";
import { AuthContext, UserContextType } from "../../context/authContext";

export interface Nav {
  isModalOpen: boolean;
  isAuth: boolean;
  updateMenuState: () => void;
}

const customStyles = {
  content: {
    height: "100vh",
    width: "75%",
    inset: "0",
  },
};

const MobileNav = ({ isModalOpen, isAuth, updateMenuState }: Nav) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { logout, isAuthenticated } = useContext(
    AuthContext
  ) as UserContextType;

  const navigate = useNavigate();

  useEffect(() => {
    setOpenModal(isModalOpen);
  }, [isModalOpen]);

  const openAssessment = () => {
    setIsOpen(!isOpen);
  };

  const closeNav = () => {
    setOpenModal(false);
    updateMenuState();
  };

  return (
    <Modal isOpen={openModal} onRequestClose={closeNav} style={customStyles}>
      <div className="mx-10 my-10">
        {/* <div className="top-sec pointer mb-20 color-healthship text-font">
          <span>
            <UserIcon />
          </span>
          <span onClick={() => navigate("/login")}>Sign Up</span>
          <span onClick={closeNav}>
            <CloseModalIcon />
          </span>
        </div> */}
        <ul className="nav-list text-font">
          <li className="color-healthship border-el">
            <div onClick={openAssessment}>
              <span>Start Assessment</span>{" "}
              {!isOpen && (
                <span style={{ paddingLeft: 10 }}>
                  <AngleDownIcon />
                </span>
              )}{" "}
              {isOpen && (
                <span style={{ paddingLeft: 10 }}>
                  <AngleUpIcon />
                </span>
              )}{" "}
            </div>
            {isOpen && (
              <ul className="assess-drop">
                <li
                  className="color-healthship"
                  onClick={() => {
                    navigate("/overall-well-being");
                  }}
                >
                  Overall Well Being
                </li>
                <li
                  className="color-healthship"
                  onClick={() => {
                    navigate("/depression");
                  }}
                >
                  Depression
                </li>
                <li
                  className="color-healthship"
                  onClick={() => {
                    navigate("/anxiety");
                  }}
                >
                  Anxiety
                </li>
                <li
                  className="color-healthship"
                  onClick={() => {
                    navigate("/career-stress");
                  }}
                >
                  Career Stress Issues
                </li>
                <li
                  className="color-healthship"
                  onClick={() => {
                    navigate("/adhd");
                  }}
                >
                  ADHD
                </li>
              </ul>
            )}
          </li>
          <li
            className="color-healthship border-el"
            onClick={() => {
              navigate("/team");
            }}
          >
            Book Appointment
          </li>
          <li
            className="color-healthship border-el"
            onClick={() => {
              navigate("/team");
            }}
          >
            Our Experts
          </li>
          {/* {isAuthenticated && (
            <li
              onClick={() => {
                logout();
              }}
            >
              Logout
            </li>
          )} */}
        </ul>
      </div>
    </Modal>
  );
};

export default MobileNav;
