import React from "react";
import "./Card.css";

interface CardProps {
  imgUrl: string;
  title: string;
  desc: string;
  index: number
}

const Card: React.FC<CardProps> = ({imgUrl, title, desc, index}) => {

  return (
    <div className="card-pot bg-lp rad-10 center" key={index}>
      <img width="84" height="84" loading='lazy' className="mt-10" src= {imgUrl} alt="growth" />
      <h2 className="white small-header-font mt-20">{title}</h2>
      <p className="white m-10 text-font">{desc}</p>
    </div>
  );
};

export default Card;
