import Footer from "../footer/Footer";
import HeaderComponent from "../header/HeaderComponent";

const Terms = () => {
  return (
    <>
      <HeaderComponent />
      <div className="text-font">
        <h1 className="header-font center my-20">Terms & Conditions</h1>
        <div className="outer-padding">
          <div className="content-section">
            <h2 className="small-header-font mb-10 mt-10 color-healthship">
              Definition
            </h2>
            <ul>
              <li>
                <p>
                  Company: Any mention of 'Healthship,' 'healthship.in,'
                  'Company,' 'Firm,' 'we,' 'our,' 'us,' 'Service Provider,' or
                  similar terms refers to Lastunicorn Health Pvt. Ltd., the
                  provider of services herein.
                </p>
              </li>
              <li>
                <p>
                  User: Refers to you, the recipient of our platform's products,
                  services, and resources.
                </p>
              </li>
              <li>
                <p>
                  Platform: Encompasses mobile apps, websites, chatbots, or
                  links facilitating access to Company-provided services.
                </p>
              </li>
              <li>
                <p>
                  Psychological Wellness Professional: Refers to practitioners
                  providing psychological wellness services.
                </p>
              </li>
            </ul>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Nature of Service
            </h3>
            <p>
              Healthship is a platform offering emotional wellness products and
              services to individuals and organizations. These include, but are
              not limited to, corporate wellness programs, online consultations,
              self-assessments, workshops, self-help tools, and participation in
              user forums.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Disclaimer
            </h3>
            <p>
              Healthship doesn't handle medical or psychological emergencies. We
              recommend seeking in-person help for severe situations like
              self-harm, suicide ideation, or severe clinical disorders.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              User Agreement
            </h3>
            <p>
              By using Healthship's services, users confirm their legal
              competence and acceptance of our Terms and Conditions ('User
              Agreement'). We may update these terms without prior notice, and
              continued use implies agreement to the revised terms
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Applicability of Terms:
            </h3>
            <p>
              The terms and conditions within this User Agreement apply to all
              current and future agreements between Healthship and users. This
              agreement is binding for all platform users, including browsers,
              vendors, customers, merchants, and content contributors. By using
              our services, users unequivocally accept the obligations stated in
              this User Agreement, as well as the Terms of Use and Privacy
              Policy, superseding any prior oral or written terms.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Termination of Agreement:
            </h3>
            <p>
              Users can terminate this agreement, provided all outstanding
              payments are settled if applicable. Upon termination, residual
              user information on our servers can be deleted upon request. Users
              accessing Healthship through third parties need to contact their
              organization for information removal. Direct platform users can
              email Healthship at care@healthship.in for data deletion,
              following our Privacy Policy and applicable laws. Healthship
              retains the right to terminate this agreement without reason
              during its term. Outstanding payments to Healthship should be
              settled within 30 days from agreement termination. Payments owed
              to users will be processed according to our Refund Policy.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Terms of Use
            </h3>
            <p>During this User Agreement:</p>
            <ul>
              <li>
                Users confirm being 18 years or older and legally competent
                under the Indian Contract Act, of 1872 and pledge not to breach
                any laws.
              </li>
              <li>
                All provided registration information, directly or through an
                organization, must be accurate and maintained.
              </li>
              <li>
                Platform use is for personal, non-commercial purposes only. Any
                other use is prohibited.
              </li>
              <li>Users agree to the following restrictions:</li>
              <li>
                Modifying, decompiling, or reverse engineering platform content
                is forbidden.
              </li>
              <li>
                Altering or deleting platform content, legal notices, or
                proprietary notices is not allowed.
              </li>
              <li>Unlawful or harmful use of the platform is prohibited.</li>
              <li>
                Reproducing, selling, or exploiting Service content without
                written permission is forbidden.
              </li>
              <li>
                Uploading harmful software or interfering with the platform is
                prohibited.
              </li>
              <li>
                Acknowledgement of external links, with the understanding that
                Healthship isn't liable for their content.
              </li>
              <li>
                Timely payment of applicable fees; late payments may incur
                additional charges.
              </li>
              <li>
                Checking technical specifications before booking sessions or
                using digital services.
              </li>
              <li>
                Not sharing or hosting misleading or offensive information or
                content that threatens security or sovereignty.
              </li>
              <li>
                These terms outline the responsible use of Healthship's platform
                and services.
              </li>
            </ul>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Intellectual Property:
            </h3>
            <p>
              The content provided on the platform is purely for informational
              purposes. Healthship disclaims any liability for reliance on any
              part of the platform, absolving itself from responsibility for any
              loss or damage caused. Third-party content displayed on the
              platform belongs to their respective owners, and Healthship does
              not assert any rights over such content. Healthship uses
              third-party content in compliance with Indian copyright laws and
              relevant provisions in other jurisdictions. Healthship claims
              copyright ownership over the remaining contents of this document.
              No information, content, or material from the platform or Services
              may be copied, reproduced, republished, uploaded, posted,
              transmitted, or distributed without explicit written permission
              from Healthship.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              User Content:
            </h3>
            <p>
              When submitting any text, links, images, recordings, audiovisual
              material, graphics, logos, trademarks, or other material for
              inclusion in the platform, user forums, comments, or any other
              submissions, you affirm and warrant to Healthship that:
            </p>
            <ul>
              <li>
                You are the rightful owner of the Content or have the necessary
                authorization or license to use, assign, or license the Content.
              </li>
              <li>
                The Content complies with the Terms and Conditions, does not
                infringe on any third-party rights, and does not promote
                restricted products or services.
              </li>
              <li>
                The Content is lawful, accurate, and does not violate any laws
                or regulations.
              </li>
              <li>
                The Content accurately reflects your products/services and
                complies with applicable laws and regulations.
              </li>
              <li>
                By submitting Content to Healthship, you grant Healthship a
                royalty-free, perpetual, non-exclusive, worldwide license to
                use, reproduce, display, distribute, and prepare derivative
                works of the Content in connection with the Service.
              </li>
            </ul>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Payment and Refunds:
            </h3>
            <p>
              By using the platform, users agree to pay all applicable fees
              upfront and authorize Healthship to deduct charges from payments
              made for accessed platform features. Users are responsible for
              telephone and internet service fees incurred while accessing
              Healthship Services.
            </p>
            <p>
              Cancellations and refunds are governed by the Company's
              Cancellation & Refund Policy, which is mentioned on the session
              booking page(where the user selects date and time for the
              session).
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Privacy Policy:
            </h3>
            <p>
              Your use of our platform or online resources is governed by our
              Privacy Policy. Please review it carefully. Healthship reserves
              the right to modify, add, or remove sections from the Privacy
              Policy without prior notice or liability to any third party.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Limitation of Liability:
            </h3>
            <p>
              Healthship doesn't guarantee acceptance as a registered user or
              client by Psychological Wellness Professionals on our platform. We
              retain the right to terminate your platform registration without
              citing a reason.
            </p>
            <p>
              We act as a technology facilitator connecting users and
              Psychological Wellness Professionals. The success of professional
              services depends on user-provided information, and Healthship
              doesn’t assure outcomes. The availability of a specific Wellness
              Professional isn't guaranteed, and session frequency isn't fixed.
            </p>
            <p>
              The Wellness Professional might determine that online counseling
              isn't suitable for your needs, opting not to provide services.
              They might also decide to involve another Wellness Professional if
              required.
            </p>
            <p>
              We make efforts to verify Wellness Professionals' credentials but
              aren't liable for misrepresentations. Professionals are
              responsible for the accuracy of information on their profiles.
            </p>
            <p>
              Healthship doesn't hold responsibility for Professional actions,
              advice, or information provided through our platform. Users are
              accountable for their decisions and actions, independent of
              representations by Healthship or Professionals.
            </p>
            <p>
              We don't endorse specific medical branches, opinions, or advice.
              Information provided on our platform should be supplemented with
              primary, accurate sources.
            </p>
            <p>
              We reserve the right to modify or terminate parts of the platform
              or Services without notice or liability to users or third parties.
            </p>
            <p>
              We don't guarantee uninterrupted, error-free platform
              functionality or virus-free content. Your reliance on platform
              information is at your own risk.
            </p>
            <p>
              We prohibit dual relationships between counsellors and clients
              outside professional sessions to maintain clarity in our
              professional relationship.
            </p>
            <p>
              You indemnify Healthship from any losses arising from your
              platform use.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Disclaimer on Warranties:
            </h3>
            <p>
              Interactions and issues with Wellness Professionals are solely
              between you and the Professional. Healthship and the Professional
              aren't responsible for these interactions or outcomes.
            </p>
            <p>
              Services, content, materials, and information provided are on an
              'as is' basis without warranties. We disclaim all warranties,
              express or implied, relating to Services, including security,
              accuracy, reliability, and performance.
            </p>
            <p>
              We make no warranties about third-party tools accessed through the
              platform or third-party websites linked on our platform.
            </p>
            <p>
              We may incorporate third-party products/services on our platform.
              Your use of these implies your agreement to the corresponding
              third-party provider's terms. Healthship isn't liable for risks,
              damages, or liabilities related to third-party products or
              services."
            </p>
            <p>
              This revised language aims to communicate the terms and conditions
              in a more concise and understandable manner while retaining the
              essential information.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              User Participation and Content Submission:
            </h3>
            <p>
              By Logging in or Signing Up on the Healthship Platform you agree
              to receive Promotional and Informational Messages from us.
            </p>
            <p>
              If you submit specific materials, such as contest entries, or
              voluntarily share forum topics, replies, comments, or other
              creative contributions (collectively, "comments"), you grant us
              the right to edit, copy, publish, distribute, and use your
              comments across any medium. While all communication between you
              and Healthship or the counselor remains confidential within
              therapy constraints as per applicable laws, your comments aren't
              confidential.
            </p>
            <p>
              You agree not to seek other users' contact information on our
              platform. Your comments must respect third-party rights, refrain
              from unlawful, abusive, or virus-containing content, and not
              misrepresent your identity. You are solely accountable for comment
              accuracy, and we disclaim liability for any user-generated
              content.
            </p>
            <p>We reserve the right to:</p>
            <ul>
              <li>
                Monitor, edit, or remove unlawful or objectionable content at
                our discretion.
              </li>
              <li>
                Investigate any non-compliance and remove or request removal of
                such content.
              </li>
              <li>Terminate a user’s access for violating these terms.</li>
              <li>
                Edit or delete any communications violating these standards.
              </li>
              <li>
                We bear no liability for these actions or inactions concerning
                user content. Healthship serves as an interactive platform where
                users and authors contribute their views and comments, which
                don't necessarily reflect Healthship's official stance.
              </li>
            </ul>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Agreement:
            </h3>
            <p>
              The User Agreement and related policies constitute the complete
              understanding between you and us, governing your platform use and
              superseding any previous agreements, oral or written.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Termination:
            </h3>
            <p>
              Terms and liabilities from before termination endure. You or we
              may end this Agreement anytime. You can terminate by notifying us
              directly or through the relevant third-party if accessing through
              one, settling outstanding payments.
            </p>
            <p>
              We reserve the right to terminate immediately if you violate the
              Agreement, remaining liable for owed amounts.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Mistakes, Inexactitudes, and Oversights:
            </h3>
            <p>
              We may occasionally have errors in platform information or
              Services. We reserve the right to rectify errors, update
              information, or cancel orders due to inaccuracies without prior
              notice, even after order submission.
            </p>
            <p>
              We aren't obligated to update or clarify information unless
              required by law. The lack of specified update dates doesn’t imply
              that all information is modified or updated.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Prohibited Uses
            </h3>
            <p>
              Apart from the restrictions outlined in this User Agreement, you
              are forbidden from using the platform or its content for:
            </p>
            <ul>
              <li>Engaging in unlawful activities.</li>
              <li>
                Soliciting or encouraging others to partake in any unlawful
                actions.
              </li>
              <li>
                Violating any local, state, provincial, federal, or
                international laws, regulations, or ordinances.
              </li>
              <li>
                Infringing upon our intellectual property rights or those of
                others.
              </li>
              <li>
                Engaging in behavior that includes harassment, abuse, insults,
                harm, defamation, or discrimination based on gender, sexual
                orientation, religion, ethnicity, race, age, national origin, or
                disability.
              </li>
              <li>Submitting false or deceptive information.</li>
              <li>
                Uploading or transmitting viruses or any malicious code that
                might affect the functionality of the Service, related websites,
                or the Internet.
              </li>
              <li>Collecting or tracking personal information of others.</li>
              <li>
                Engaging in spamming, phishing, pretexting, or scraping
                activities.
              </li>
              <li>Pursuing any obscene or immoral objectives.</li>
              <li>
                Interfering with or circumventing the security features of the
                Service, related websites, or the Internet.
              </li>
            </ul>
            <p>
              We reserve the right to terminate your use of the Service or any
              associated website, mobile application, or platform if you violate
              any of these prohibited uses.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Disclaimer of Warranties; Limitation of Liability
            </h3>
            <ul>
              <li>
                We do not guarantee, represent, or warrant that your use of our
                Service will be uninterrupted, timely, secure, or error-free.
              </li>
              <li>
                You agree to indemnify healthship against any breaches in
                confidentiality. If you access healthship through a third-party
                platform, you acknowledge that healthship cannot be held
                responsible for any data breaches resulting from acts of
                commission or omission by the third-party platform.
              </li>
              <li>
                We cannot ensure that the results obtained from using the
                Service will always be accurate or reliable.
              </li>
              <li>
                You agree that we may, from time to time, remove the Service (or
                parts thereof) for indefinite periods or cancel it without
                notice.
              </li>
              <li>
                You expressly understand and agree that your use of, or
                inability to use, the Service is at your sole risk. The Service,
                and all products and Services delivered to you through the
                Service, are provided "as is" and "as available" without any
                warranties or conditions, whether express or implied.
              </li>
              <li>
                In no event shall healthship, our directors, officers,
                employees, affiliates, agents, contractors, interns, suppliers,
                Service providers, or licensors be liable for any injury, loss,
                claim, or any direct, indirect, incidental, punitive, special,
                or consequential damages of any kind, including, without
                limitation, lost profits, revenue, savings, data, or replacement
                costs, arising from your use of the Services or any products
                obtained through the Services, or for any other claim related in
                any way to your use of the Services or any product, including,
                but not limited to, any errors or omissions in any content, or
                any loss or damage of any kind incurred as a result of the use
                of the Services or any content posted, transmitted, or otherwise
                made available through the Service or platform, even if advised
                of their possibility. In any case, our maximum liability shall
                be limited to the amount paid by you, if any, for the disputed
                Service/product.
              </li>
            </ul>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Indemnification
            </h3>
            <p>
              You agree to indemnify, defend, and hold harmless healthship and
              our subsidiaries, affiliates, partners, officers, directors,
              agents, contractors, licensors, Service providers, subcontractors,
              suppliers, employees, and interns against any claim or demand,
              including reasonable attorneys’ fees, made by any third-party due
              to or arising out of your breach of this User Agreement or the
              documents incorporated by reference, or your violation of any law
              or the rights of a third-party. You agree to indemnify healthship
              for any breaches in confidentiality. If you access healthship
              through a third-party platform, you indemnify healthship against
              any data breaches resulting from acts of commission or omission by
              the third-party platform.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Severability
            </h3>
            <p>
              If any provision of this User Agreement is found to be unlawful,
              void, or unenforceable, such provision shall be enforceable to the
              fullest extent permitted by applicable law. The unenforceable
              portion shall be deemed severed from these Terms of Service
              without affecting the validity and enforceability of the remaining
              provisions.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Grievance Redressal
            </h3>
            <p>
              We have established a Grievance Redressal Forum to address user
              grievances. If you are dissatisfied with any aspect of our
              Services, you may contact us at care@healthship.in. We assure you
              of a time-bound solution not exceeding one month from the date of
              your complaint.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Jurisdiction
            </h3>
            <p>
              Healthship's jurisdictional policies comply with Indian laws. Any
              disputes between the user and healthship regarding this User
              Agreement, including its interpretation, shall be exclusively
              subject to the jurisdiction of the courts in Jodhpur, Rajasthan,
              India.
            </p>
            <h3 className="small-header-font mb-10 mt-10 color-healthship">
              Contact Information
            </h3>
            <p>
              Registered Address: 76 Sardar Club Scheme, Ratanada, Jodhpur,
              Rajasthan, 342001 E-mail id: care@healthship.in
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
