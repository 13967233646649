import { http } from "../utils/http-common";

function getCounsellorsData() {
  return http.get("/counsellors");
}

function getCounsellorDataById(id: string) {
  return http.get(`/counsellor/${id}`);
}

function getCounsellorSessionDataById(id: any) {
    return http.get(`/counsellor/${id}/sessions`);
}

export { getCounsellorsData, getCounsellorDataById, getCounsellorSessionDataById };
