import WhatsApp from "../../images/whatsapp.png";

const WhatsappBtn = () => {
  return (
    <a
      href="https://wa.me/918076200720?text=Hi%20There!"
      className="float"
      style={{
        position: "fixed",
        width: 50,
        height: 50,
        bottom: 100,
        right: 20,
        color: "#fff",
        borderRadius: 50,
        textAlign: "center",
        cursor: "pointer",
        boxShadow: "2px 2px 3px #999",
      }}
    >
        <img src={WhatsApp} alt="WhatsApp Icon" height="60px" width="60px" />
    </a>
  );
};

export default WhatsappBtn;