import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HeaderComponent from "../header/HeaderComponent";
import "./Home.css";
import ImageGrid from "./ImageGrid";
import ConnectImage from "../../images/Friends.webp";
import Footer from "../footer/Footer";
import PotentialSection from "./PotentialSection";
import { ArrowRightIcon } from "../icons";
import Meditation from "../../images/meditations.webp";

import {
  TakeAssessmentData,
  TakeAssessmentList,
  AssessmentData,
} from "./HomeData";
import { useNavigate } from "react-router-dom";
import StyledButton from "../ui/StyledButton/StyledButton";
import WhatsappBtn from "../ui/WhatsappBtn";

const Home: React.FC = () => {
  const [selectedDiv, setSelectedDiv] = useState(0);
  const [assessmentData, setAssessmentData] = useState({
    imgUrl: "",
    title: "Overall Well Being",
    desc: "Take this assessment to know more about your psychological well-being and get matched to the right counsellor according to your needs.",
    time: "5 mins",
    redirectionUrl: "/overall-well-being"
  });
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if(window.innerWidth > 765) {
      setIsMobile(false);
    }
  }, []);

  const navigation = useNavigate();

  function switchAssessmentCard(item: string) {
    setAssessmentData(TakeAssessmentData[item]);
  }

  return (
    <>
      <Helmet>
        <title>Healthship | Sail to Wellness</title>
        <meta
          name="description"
          content="Healthship is a place to unwind your emotions and take care of your mental health at your own pace."
        />
      </Helmet>
      <HeaderComponent />
      <div className="home-page min-dim">
        {/* Section 1 */}
        <section className="section">
          <div className="discover-sec">
            <div className="disc-left">
              <h1 className="header-font color-healthship">
                Rediscover Yourself
              </h1>
              <h2 className="small-header-font color-healthship">
                Find Strength. Find Support. Find Peace
              </h2>

              <p className="desc-discover text-font">
                Healthship helps you feel better and elevate the way you
                experience life. Our new healthcare experience is
                judgement-free, inclusive and provides confidential treatment
                for patients from verified doctors.
              </p>
              <StyledButton
                type="button"
                className="button large"
                onClick={() => navigation("/team")}
              >
                Book Appointment Now
                <ArrowRightIcon />
              </StyledButton>
            </div>
            <div>
              <ImageGrid isMobile={isMobile} />
            </div>
          </div>
        </section>
        {/* End of section 1 */}
        {/* Section 2 */}
        <section>
          <div className="begin-sec">
            <div className="section">
              <h1 className="header-font">Not Sure Where To Begin?</h1>
              <p className="text-font">
                Try our free assessment validated by clinical experts to gain
                personalized insights into your mental well-being. And start
                your journey to rediscover your inner strength with us by your
                side
              </p>
              <div className="symptom-tab mt-50 text-font">
                {TakeAssessmentList.map((item, index) => (
                  <div
                    onClick={() => {
                      switchAssessmentCard(item);
                      setSelectedDiv(index);
                    }}
                    className={selectedDiv === index ? "assessment-selected" : ""}
                    key={index}
                  >
                    {item}
                  </div>
                ))}
              </div>
              <div className="symptom-card">
                <div className="mr-20 img-left">
                  <img loading="lazy" src={Meditation} alt="symptom" />
                </div>
                <div>
                  <p className="text-font mt-20 mb-10">
                    {" "}
                    {assessmentData.desc}
                  </p>
                  <p className="text-font mt-20 mb-10">
                    Estimated time required: {assessmentData.time}
                  </p>
                  <StyledButton
                    className="button small"
                    type="button"
                    onClick={() => navigation(assessmentData.redirectionUrl)}
                  >
                    <span>Assess Your Symptoms</span>
                    <span>
                      <ArrowRightIcon />
                    </span>
                  </StyledButton>
                </div>
              </div>
              <div className="unsure mt-50">
                <div className="header-font mb-10">Not sure what to do?</div>
                <div className="chat-with-us small-header-font ml-auto">
                  <a className="black pointer" href="https://wa.me/918076200720">Chat With Us</a>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End of section 2 */}
        {/* Section 3 */}
        <PotentialSection />
        {/* End of section 3 */}
        {/* Section 4 */}
        <section className="processes-section">
          <div className="section">
            <h1 className="color-healthship header-font">
              See Our Process In Action
            </h1>
            <div className="process-cards">
              {AssessmentData.map((item, index) => (
                <div className="process-card" key={index}>
                  <img loading="lazy" src={item?.imgUrl} alt="assignment" />
                  <h2 className="small-header-font">{item.title}</h2>
                  <p className="text-font">{item.desc}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
        {/* End of section 4 */}
        {/* Section 5 */}
        <section className="connect-section">
          <div className="section">
            <div className="connect-left">
              <h1 className="header-font color-healthship">
                Need Help in Helping a Loved One?
              </h1>
              <p className="text-font">
                All of us need an extra bit of help sometimes. If you want
                guidance on how to manage mental health better for yourself or a
                loved one we are here to help you. Begin a new journey to
                discover yourself with Healthship.
              </p>
              <StyledButton type="button" className="button large">
                <span><a className="white pointer" href="https://wa.me/918076200720">Connect with Us</a></span>
                <span>
                  <ArrowRightIcon />
                </span>
              </StyledButton>
            </div>
            <div className="connect-right">
              <img loading="lazy" src={ConnectImage} alt="connect" />
            </div>
          </div>
        </section>
        {/* End of section 5 */}
      </div>
      <WhatsappBtn />
      <Footer />
    </>
  );
};

export default Home;
