// Navbar.tsx
import React, { useContext, useState } from "react";
import "./HeaderComponent.css";
import TitleImage from "../../images/healthshiplogo.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext, UserContextType } from "../../context/authContext";
import MobileNav from "./MobileNav";
import { UserIcon, CloseModalIcon } from "../icons";
import Sticky from "react-stickynode";
import { ToastContainer } from "react-toastify";

const HeaderComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [toggleAsseesmentDropdown, setToggleAssessmentDropdown] =
    useState(false);
  const [isModal, setIsModal] = useState(true);

  const { logout, isAuthenticated } = useContext(
    AuthContext
  ) as UserContextType;

  const updateMenuState = () => {
    setIsMobileMenuOpen(false);
  };

  const handlePopup = () => {
    setIsModal(false);
  };

  return (
    <header className="header">
      <Sticky>
        {isModal && currentPath == "/checkout" && (
          <div className="coupon-strip">
            <div className="text-font pointer m-auto">
              <div>
                You’ll be asked to select the date and time of your session
                post-payment.
              </div>
            </div>
            <div className="ml-10" onClick={handlePopup}>
              <CloseModalIcon />
            </div>
            <ToastContainer />
          </div>
        )}

        <nav className="navbar">
          <div className="pointer">
            <img
              width={260}
              height={60}
              style={{
                minWidth: "260",
                minHeight: "60",
              }}
              className="title-img"
              src={TitleImage}
              alt="title"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          <div
            className="hamburger pointer"
            onClick={() => setIsMobileMenuOpen((prevState) => !prevState)}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
          <MobileNav
            isModalOpen={isMobileMenuOpen}
            isAuth={isAuthenticated}
            updateMenuState={updateMenuState}
          />
          <ul className="nav-menu">
            <li className="menu-item-dropdown nav-item color-healthship text-font">
              <div
                onClick={() =>
                  setToggleAssessmentDropdown(!toggleAsseesmentDropdown)
                }
                className="nav-link color-healthship"
              >
                Start Assessment
              </div>
              {toggleAsseesmentDropdown && (
                <div className="dropdown-content">
                  <div
                    onClick={() => {
                      navigate("/overall-well-being");
                    }}
                    className="nav-link"
                  >
                    Overall Well-Being
                  </div>
                  <div
                    onClick={() => {
                      navigate("/depression");
                    }}
                    className="nav-link color-healthship"
                  >
                    Depression
                  </div>
                  <div
                    onClick={() => {
                      navigate("/anxiety");
                    }}
                    className="nav-link"
                  >
                    Anxiety
                  </div>
                  <div
                    onClick={() => {
                      navigate("/career-stress");
                    }}
                    className="nav-link color-healthship"
                  >
                    Career Stress Issues
                  </div>
                  <div
                    onClick={() => {
                      navigate("/adhd");
                    }}
                    className="nav-link color-healthship"
                  >
                    ADHD
                  </div>
                </div>
              )}
            </li>
            <li className="nav-item color-healthship text-font">
              <div
                className="nav-link color-healthship"
                onClick={() => {
                  navigate("/team");
                }}
              >
                Book Appointment
              </div>
            </li>
            <li className="nav-item color-healthship text-font">
              <div
                className="nav-link color-healthship"
                onClick={() => {
                  navigate("/team");
                }}
              >
                Our Experts
              </div>
            </li>
            <li></li>
            {/* <li className="menu-item-dropdown nav-item color-healthship text-font pointer signup">
              <div
                onClick={() => {
                  navigate("/login");
                }}
              >
                <span style={{ marginRight: 10 }}>
                  <UserIcon />
                </span>
                Sign Up
              </div>
            </li> */}
          </ul>
        </nav>
      </Sticky>
    </header>
  );
};

export default HeaderComponent;
